import React from 'react'
import s from './StandardCTA.module.scss'
import { Container, DynamicLink } from '~views/shared/components'
import { IStandardCTASection } from '~models'
import { Icons } from '~libs/assets'
import { createMarkup } from '~libs/utils'

interface Props {
  section: IStandardCTASection
}
export class StandardCTASection extends React.Component<Props> {
  render() {
    const { section } = this.props
    const { buttonTitle, linkInformation, additionalInformation } = section
    const { linkType, externalLink, internalLink } = linkInformation

    const arrowClasses = [s.arrowRight]
    let link

    switch (linkType) {
      case 'internal':
        link = internalLink
        break
      case 'external':
        link = externalLink
        arrowClasses.push(s.external)
        break
    }

    return (
      <section data-sectionname="StandardCTA">
        <Container noPadding className={s.customContainer}>
          <DynamicLink to={link} className={s.button}>
            <div className={s.buttonTitle}>{buttonTitle}</div>
            <img src={Icons.arrow_right} className={arrowClasses.join(' ')} />
          </DynamicLink>
          <div
            className={s.additionalInformation}
            dangerouslySetInnerHTML={createMarkup(additionalInformation)}
          />
        </Container>
      </section>
    )
  }
}
