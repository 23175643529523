import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { inject, observer } from 'mobx-react'
import { IFeedbacKButton } from '~models'
import s from './FeedbackButton.module.scss'
import { WpmlStore, UIStore } from '~stores'
import { DynamicLink } from '~views/shared/components'

interface FeedbackButtonProps {
  data?: IFeedbacKButton
  wpmlStore?: WpmlStore
  uiStore?: UIStore
}

@inject('wpmlStore', 'uiStore')
@observer
export class FeedbackButtonComponent extends Component<FeedbackButtonProps> {
  render() {
    const { wpmlStore, uiStore, data } = this.props
    const { themeSettingsLanguages } = data.wp.themeSettingsLanguages
    const { lang } = wpmlStore

    const subject =
      lang === 'en'
        ? '?Subject=Feedback%20to%20Villa%20Baro'
        : '?Subject=Feedback%20till%20Villa%20Baro'
    const settings =
      lang === 'en'
        ? themeSettingsLanguages.englishSettings.settingsFeedbackButton
        : themeSettingsLanguages.swedishSettings.settingsFeedbackButton

    const feedbackShow = settings.feedbackShow
    const feedbackTitle = settings.feedbackTitle
    const feedbackEmail = settings.feedbackEmail

    const href = 'mailto:' + feedbackEmail + subject
    var buttonClasses = [s.button]

    var yOffset
    if (uiStore) yOffset = uiStore.windowPageYOffset
    if (feedbackShow && yOffset > 300) buttonClasses.push(s.visible)

    return (
      <DynamicLink className={buttonClasses.join(' ')} to={href}>
        {feedbackTitle}
      </DynamicLink>
    )
  }
}

export const FeedbackButton = (props: FeedbackButtonProps) => (
  <StaticQuery
    query={query}
    render={data => <FeedbackButtonComponent {...props} data={data} />}
  />
)

const query = graphql`
  query {
    wp {
      themeSettings {
        themeSettingsLanguages {
          themeSettingsLanguages {
            englishSettings {
              settingsFeedbackButton {
                feedbackShow
                feedbackTitle
                feedbackEmail
              }
            }
            swedishSettings {
              settingsFeedbackButton {
                feedbackShow
                feedbackTitle
                feedbackEmail
              }
            }
          }
        }
      }
    }
  }
`
