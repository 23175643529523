import moment from 'moment'
import { observable, action, toJS } from 'mobx'

export class ActivityFilterStore {
  @observable
  filterOpen: boolean = false

  @observable
  activityTypes_sv: string[] = []

  @observable
  activityTypes_en: string[] = []

  @observable
  unsortedActivities: any

  @observable
  sortedActivitiesByYear: { [key: string]: [string] } = {}

  @observable
  filteredAndSortedActivitiesByYear: { [key: string]: [string] } = {}

  @observable
  selectedTypeFilterOptions: string[] = []

  @observable
  selectedTimeFilterOptions: string[] = []

  @observable
  twelveMonthsAheadFromToday: string[] = []

  @observable
  twelveMonthsAheadSortedByYear: { [key: string]: [string] } = {}

  toggleFilterDropdown = () => {
    this.filterOpen = !this.filterOpen
  }

  @action
  initiateActivityFilter = () => {
    this.getTwelveMonthsAhead()
    this.sortMonthsByYear()
  }

  @action
  setUnsortedActivities = (activities) => {
    this.unsortedActivities = activities
  }

  getTwelveMonthsAhead = () => {
    let index = 1
    const today = moment().utc()
    const formattedToday = today.format('YYYY-MM-DD')

    if (!this.twelveMonthsAheadFromToday.includes(formattedToday)) {
      this.twelveMonthsAheadFromToday.push(formattedToday)
    }

    while (index < 12) {
      const futureMonth = moment(today).add(index, 'months').calendar()

      const formattedFutureMonth = moment(futureMonth).format('YYYY-MM-DD')
      if (!this.twelveMonthsAheadFromToday.includes(formattedFutureMonth)) {
        this.twelveMonthsAheadFromToday.push(formattedFutureMonth)
      }
      index++
    }
  }

  sortMonthsByYear = () => {
    const twelveMonthsAheadSortedByYear = this.twelveMonthsAheadSortedByYear
    const twelveMonthsAheadFromToday = this.twelveMonthsAheadFromToday
    twelveMonthsAheadFromToday &&
      twelveMonthsAheadFromToday.sort().map((date) => {
        const year = this.getYearFromDateString(date)
        if (
          twelveMonthsAheadSortedByYear[year] &&
          !twelveMonthsAheadSortedByYear[year].includes(date)
        ) {
          twelveMonthsAheadSortedByYear[year].push(date)
        } else {
          twelveMonthsAheadSortedByYear[year] = [date]
        }
      })
  }

  getYearFromDateString = (date) => {
    return date.length > 0 ? date.slice(0, 4) : ''
  }

  collectAllActivityTypes = (activities) => {
    activities.forEach((activityItem) => {
      const currentActivityItemTypes = activityItem.activity
      currentActivityItemTypes.forEach((activityType) => {
        if (activityItem.wpml_current_locale === 'sv') {
          if (!this.activityTypes_sv.includes(activityType.name)) {
            this.activityTypes_sv.push(activityType.name)
          }
        } else if (activityItem.wpml_current_locale === 'en') {
          if (!this.activityTypes_en.includes(activityType.name)) {
            this.activityTypes_en.push(activityType.name)
          }
        }
      })
    })
  }

  @action
  sortActivitiesByYear = (activities) => {
    activities.forEach((activity) => {
      const activityYear = this.getYearFromDateString(activity.page.activityStartDate)
      if (
        this.sortedActivitiesByYear[activityYear] &&
        !this.sortedActivitiesByYear[activityYear].includes(activity)
      ) {
        this.sortedActivitiesByYear[activityYear].push(activity)
      } else if (!Object.values(this.sortedActivitiesByYear).includes(activity)) {
        this.sortedActivitiesByYear[activityYear] = [activity]
      }
    })
    this.filteredAndSortedActivitiesByYear = toJS(this.sortedActivitiesByYear)
  }

  @action
  sortFilteredActivitiesByYear = (activities) => {
    this.filteredAndSortedActivitiesByYear = {}
    activities.forEach((activity) => {
      const activityYear = this.getYearFromDateString(activity.acf.activity_start_date)
      if (
        this.filteredAndSortedActivitiesByYear[activityYear] &&
        !this.filteredAndSortedActivitiesByYear[activityYear].includes(activity)
      ) {
        this.filteredAndSortedActivitiesByYear[activityYear].push(activity)
      } else if (
        !Object.values(this.filteredAndSortedActivitiesByYear).includes(activity)
      ) {
        this.filteredAndSortedActivitiesByYear[activityYear] = [activity]
      }
    })
  }

  toggleFilterOption = (element, type) => {
    const target = element.target
    let elementValue = target.dataset.value
    const elementYear = target.dataset.year

    let resetValue
    let filterCollection

    switch (type) {
      case 'activity':
        resetValue = 'allTypes'
        filterCollection = this.selectedTypeFilterOptions
        break
      case 'time':
        resetValue = 'allMonths-reset'
        filterCollection = this.selectedTimeFilterOptions
        elementValue += '-' + elementYear
        break
    }

    if (elementValue === resetValue) {
      type === 'activity'
        ? (this.selectedTypeFilterOptions = [])
        : (this.selectedTimeFilterOptions = [])
    } else {
      if (!filterCollection.includes(elementValue)) {
        filterCollection.push(elementValue)
      } else {
        const index = filterCollection.indexOf(elementValue)
        if (index > -1) {
          filterCollection.splice(index, 1)
        }
      }
    }
  }

  @action
  filterActivitiesByType = (activities) => {
    const typeFilter = this.selectedTypeFilterOptions
    let tempFilteredActivities = []

    activities.forEach((activityItem) => {
      let currentActivityItemTypes = activityItem.activity
      currentActivityItemTypes.forEach((activityType) => {
        if (
          typeFilter.includes(activityType.name) &&
          !tempFilteredActivities.includes(activityItem)
        ) {
          tempFilteredActivities.push(activityItem)
        }
      })
    })
    if (tempFilteredActivities.length === 0) {
      return activities
    } else {
      return tempFilteredActivities
    }
  }

  @action
  filterActivitiesByTime = (activities) => {
    const timeFilter = this.selectedTimeFilterOptions
    let tempFilteredActivities = []

    activities.forEach((activityItem) => {
      let currentActivityItemStartDate = activityItem.acf.activity_start_date
      let UTCDate = new Date(currentActivityItemStartDate)
      let year = UTCDate.toLocaleDateString('en-GB', { year: 'numeric' })
      let month = UTCDate.toLocaleDateString('en-GB', { month: 'long' })
      const currentActivityItemEndDate = activityItem.acf.activity_end_date

      if (currentActivityItemEndDate) {
        const startDateParsed = Date.parse(activityItem.acf.activity_start_date)
        const endDateParsed = Date.parse(activityItem.acf.activity_end_date)

        timeFilter.forEach((selectedMonth) => {
          const formattedSelectedMonth = moment()
            .month(selectedMonth)
            .date(1)
            .utc()
            .format('YYYY-MM-DD')
          const selectedDateParsed = Date.parse(formattedSelectedMonth)

          if (
            startDateParsed <= selectedDateParsed &&
            selectedDateParsed <= endDateParsed
          ) {
            if (!tempFilteredActivities.includes(activityItem)) {
              tempFilteredActivities.push(activityItem)
            }
          }
        })
      }

      if (
        timeFilter.includes(month + '-' + year) &&
        !tempFilteredActivities.includes(activityItem)
      ) {
        tempFilteredActivities.push(activityItem)
      } else if (
        timeFilter.length === 0 &&
        !tempFilteredActivities.includes(activityItem)
      ) {
        tempFilteredActivities.push(activityItem)
      }
    })

    return tempFilteredActivities
  }

  @action
  filterActivities = () => {
    const unsortedActivities = this.unsortedActivities
    const activitiesFilteredByType = this.filterActivitiesByType(unsortedActivities)
    const activitiesFilteredByTypeAndTime = this.filterActivitiesByTime(
      activitiesFilteredByType,
    )
    this.sortFilteredActivitiesByYear(activitiesFilteredByTypeAndTime)
  }

  @action
  applyFilter = () => {
    this.filterActivities()
    this.filterOpen = !this.filterOpen
  }
}
