import React from 'react'
import s from './BookingCTA.module.scss'
import { Container } from '~views/shared/components'
import { ICTASection } from '~models'
import { Icons } from '~libs/assets'
import { BookingCTADropdown } from './BookingCTADropdown'
import { ContextStore } from '~stores'
import { inject, observer } from 'mobx-react'

interface Props {
  section?: ICTASection
  contextStore?: ContextStore
}

@inject('contextStore')
@observer
export class BookingCTASection extends React.Component<Props> {
  state = {
    ctaOpen: false,
    isFrontPage: false,
  }

  render() {
    const { section, contextStore } = this.props
    const { ctaOpen } = this.state
    const { startpage } = contextStore

    const ctaSectionClasses = []
    const ctaWrapperClasses = [s.ctaWrapper]
    const titleWrapperClasses = [s.titleWrapper]
    const ctaTitleClasses = [s.ctaTitle]
    const chevronClasses = [s.chevronDown]

    if (startpage) {
      ctaSectionClasses.push(s.startpage)
    }

    if (section && section.size === 'big') {
      ctaWrapperClasses.push(s.big)
      ctaTitleClasses.push(s.big)
      chevronClasses.push(s.big)
      titleWrapperClasses.push(s.big)
    }

    if (ctaOpen) {
      chevronClasses.push(s.open)
    }

    return (
      <section data-sectionname="BookingCTA" className={ctaSectionClasses.join(' ')}>
        <Container>
          <div className={ctaWrapperClasses.join(' ')}>
            <div
              className={titleWrapperClasses.join(' ')}
              onClick={() => this.setState({ ctaOpen: !ctaOpen })}
            >
              <h4 className={ctaTitleClasses.join(' ')}>
                {section && section.title ? section.title : ' '}
              </h4>
              <img
                src={Icons.triangle_down}
                className={chevronClasses.join(' ')}
                alt="chevron down"
              />
            </div>
            <BookingCTADropdown open={ctaOpen} />
          </div>
        </Container>
      </section>
    )
  }
}
