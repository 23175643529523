import React, { Component } from 'react'

export interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  noMargin?: boolean
}

export class Row extends Component<RowProps> {
  render() {
    const { noMargin, className, ...rest } = this.props
    const classes = ['row']
    if (className) {
      classes.push(className)
    }
    if (noMargin) {
      classes.push('no-margin')
    }
    return <div className={classes.join(' ')} {...rest} />
  }
}
