import React from 'react'
import s from './Banner.module.scss'
import { Container } from '~views/shared/components'
import { IBannerSection } from '~models'
import BackgroundImage from '~views/shared/components/BackgroundImage'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import { Icons } from '~libs/assets'
import { UIStore } from '~stores'
import { inject, observer } from 'mobx-react'

interface Props {
  section: IBannerSection
  uiStore?: UIStore
}

@inject('uiStore')
@observer
export class BannerSection extends React.Component<Props> {
  render() {
    const { section, uiStore } = this.props
    const { image, title, text, link } = section
    const { windowWidth } = uiStore

    let sectionClasses = []

    if (windowWidth <= 1456) {
      sectionClasses.push(s.fullscreen)
    }

    return (
      <section data-sectionname="Banner" className={sectionClasses.join(' ')}>
        <Container noPadding className={s.customContainer}>
          <BackgroundImage image={image} className={s.backgroundImage} />
          <div className={s.textWrapper}>
            <h2 className={s.title}>{title}</h2>
            <h2 className={s.text}>{text}</h2>
          </div>
          <div className={s.linkWrapper}>
            <DynamicLink to={link.link}>
              <img src={Icons.arrow_right} className={s.icon} alt="link arrow right" />
            </DynamicLink>
          </div>
        </Container>
      </section>
    )
  }
}
