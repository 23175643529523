import React from 'react'
import { IImageCardsRowSection } from '~models'
import s from './ImageCardsRow.module.scss'
import { Container } from '~views/shared/components'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import BackgroundImage from '~views/shared/components/BackgroundImage'

interface Props {
  section: IImageCardsRowSection
}

export class ImageCardsRowSection extends React.Component<Props> {
  renderCard = (item, key) => {
    const { title, link, image } = item
    return (
      <div className={s.card} key={key}>
        <DynamicLink to={link?.link}>
          <BackgroundImage image={image} className={s.image} />
        </DynamicLink>
        <h3 className={s.cardTitle}>{title}</h3>
      </div>
    )
  }

  render() {
    const { section } = this.props
    const { sectionTitle, cards } = section
    return (
      <section data-sectionname="ImageCardsRow">
        <Container noPadding fluid className={s.container}>
          {sectionTitle && <h2 className={s.sectionTitle}>{sectionTitle}</h2>}
          <div className={s.cardsContainer}>{cards && cards.map(this.renderCard)}</div>
        </Container>
      </section>
    )
  }
}
