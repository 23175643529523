import React from 'react'
import s from './Footer.module.scss'
import { Icons } from '~libs/assets'
import { Container } from '~views/shared/components'

export const FooterDecoration = () => {
  return (
    <div className={s.decorationWrapper}>
      <div className={s.greenWaves}>
        <img src={Icons.green_wave_png} alt="green wave" />
      </div>
      <Container noPadding className={s.swanWrapper}>
        <img src={Icons.swan_png} className={s.swan} alt="swan" />
      </Container>
      <div className={s.blueWaves}>
        <img src={Icons.blue_wave_png} alt="blue wave" />
      </div>
    </div>
  )
}
