import React from 'react'
import s from './BookingCTA.module.scss'
import { BookingCTADatepicker } from './BookingCTADatepicker'
import { BookingCTAGuests } from './BookingCTAGuests'
import { inject, observer } from 'mobx-react'
import { BookingStore, WpmlStore } from '~stores'
import WidgetCTAColumns from './WidgetCTAColumns'

interface Props {
  open: boolean
  bookingStore?: BookingStore
  wpmlStore?: WpmlStore
}

@inject('bookingStore', 'wpmlStore')
@observer
export class BookingCTADropdown extends React.Component<Props> {
  render() {
    const { open } = this.props
    const dropdownsWrapperClasses = [s.dropdownsWrapper]

    if (open) {
      dropdownsWrapperClasses.push(s.open)
    }

    return (
      <div className={dropdownsWrapperClasses.join(' ')}>
        <WidgetCTAColumns />
      </div>
    )
  }
}
