import React from 'react'
import s from './Offer.module.scss'
import { Container, Row } from '~views/shared/components'
import { IOfferSection } from '~models'
import { createMarkup } from '~libs/utils'
import { inject, observer } from 'mobx-react'
import { BookingStore } from '~stores'
import BookingButton from './BookingButton'

interface Props {
  section: IOfferSection
  bookingStore?: BookingStore
}

@inject('bookingStore')
@observer
export class OfferSection extends React.Component<Props> {
  renderText = (textObject, key) => {
    const { type, bulletPoints, features } = textObject

    let element = null
    let textClasses = [s.text]

    switch (type) {
      case 'bullet_points':
        element = this.renderBulletPointList(bulletPoints)
        break
      case 'features':
        const half = features.length > 1 ? Math.floor(features.length / 2) : 1
        textClasses.push(s.feature)
        element = (
          <div className={s.features}>
            <div className={s.featuresLeft}>
              {features &&
                features
                  .slice(0, half)
                  .map((item, key) => this.renderFeature(item.feature, key))}
            </div>
            <div className={s.featuresRight}>
              {features &&
                features
                  .slice(half, features.length)
                  .map((item, key) => this.renderFeature(item.feature, key))}
            </div>
          </div>
        )
        break
    }

    return (
      <div key={key} className={textClasses.join(' ')}>
        {element}
      </div>
    )
  }

  renderFeature = (feature, key) => {
    return (
      <div className={s.feature} key={key}>
        {feature}
      </div>
    )
  }

  renderBulletPointList = (bulletPoints) => {
    const { title, points } = bulletPoints

    return (
      <div className={s.bulletPointsListWrapper}>
        <div className={s.bulletPointTitle}>{title}</div>
        <ul className={s.bulletPointsList}>
          {points && points.map((item, key) => this.renderBulletPoint(item.point, key))}
        </ul>
      </div>
    )
  }

  renderBulletPoint = (bulletPoint, key) => {
    return (
      <li className={s.bulletPointListItem} key={key}>
        {bulletPoint}
      </li>
    )
  }

  render() {
    const { section } = this.props
    const { offerTitle, offerIntroduction, leftColumn, rightColumn } = section
    const { wysiwyg } = leftColumn
    const { bookingBox, information } = rightColumn
    const { text } = bookingBox

    let bookingboxClasses = [s.bookingBox]

    if (text.type === 'features') {
      bookingboxClasses.push(s.features)
    }

    return (
      <section data-sectionname="Offer">
        <Container noPadding fluid className={s.customContainer}>
          <Row noMargin className={s.sectionInformation}>
            <h2 className={s.offerTitle}>{offerTitle}</h2>
            <div className={s.offerIntroduction}>
              <p className="ingress">{offerIntroduction}</p>
            </div>
          </Row>
          <div className={s.columnWrapper}>
            <div className={s.leftColumn}>
              {wysiwyg && (
                <div
                  className={s.wysiwyg}
                  dangerouslySetInnerHTML={createMarkup(wysiwyg)}
                />
              )}
            </div>
            <div className={s.rightColumn}>
              <div className={bookingboxClasses.join(' ')}>
                {this.renderText(text, 'bookingBox')}
              </div>
              <BookingButton />
              <div
                className={s.bookingInformation}
                dangerouslySetInnerHTML={createMarkup(information)}
              />
            </div>
          </div>
        </Container>
      </section>
    )
  }
}
