import React from 'react'
import s from './BookingCTA.module.scss'
import { ICTASection } from '~models'
import { ContextStore } from '~stores'
import { Container } from '~views/shared/components'
import { inject, observer } from 'mobx-react'

interface Props {
  section?: ICTASection
  contextStore?: ContextStore
}

@inject('contextStore')
@observer
export class BookingTable extends React.Component<Props> {
  state = {
    ctaOpen: true,
  }

  render() {
    const tableWidgetScript = document.createElement('script')
    tableWidgetScript.async = true
    tableWidgetScript.src =
      '//online.bookvisit.com/Content/widget/table_reservation_widget.bundle.js'

    document.head.appendChild(tableWidgetScript)

    const handleRoomBooking = () => {
      const config = {
        widgetContainerId: 'widgetContainer',
        open: true,
        channelId: 'de41fdd8-59ac-404b-b136-9bd109541dce',
        loginLink: false,
        languageCulture: 'sv-SE',
        restaurantId: '1f234dc5-1f3c-436e-a8c6-899ae0170fd2',
        singleRestaurant: true,
      }
      ;(window as any).table_reservation_widget.TableReservationWidget({ ...config })
    }

    return (
      <section>
        <div id="widgetContainer"></div>
        <div className={s.tableBookingButton}>
          <button onClick={handleRoomBooking} id="table-reservation">
            Till bordsbokning
          </button>
        </div>
      </section>
    )
  }
}
