import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { IHeaderCTA } from '~models'
import s from './Header.module.scss'
import { inject, observer } from 'mobx-react'
import { NavigationStore, WpmlStore } from '~stores'
import { BookingCTASectionNavbar } from '~views/shared/sections/BookingCTA/BookingCTANavbar'
import { BookingTable } from '~views/shared/sections/BookingCTA/BookingTable'
import { ICTASection } from '~models'
import { Icons } from '~libs/assets'

interface Props {
  data: IHeaderCTA
  navigationStore?: NavigationStore
  wpmlStore?: WpmlStore
}

@inject('navigationStore', 'wpmlStore')
@observer
export class HeaderBookingMenuComponent extends Component<Props> {
  render() {
    const { bookingmenuOpen, toggleBookingMenu, toggleActivityMenu, activityMenu } =
      this.props.navigationStore
    const { lang } = this.props.wpmlStore
    const { themeSettingsLanguages } =
      this.props.data.wp.themeSettings.themeSettingsLanguages
    const { headerCta } =
      lang === 'en'
        ? themeSettingsLanguages.englishSettings.settingsHeader
        : themeSettingsLanguages.swedishSettings.settingsHeader
    const {
      bookingActivities,
      headerCtaLink,
      headerCtaLinkTwo,
      roomLinkText,
      roomLinkTextTwo,
    } = headerCta
    const openBookingClasses = [s.openBooking]
    const bookingCloseButtonClass = [s.bookingCloseButton]
    const bookingActivitiesClass = [s.bookingActivities]
    const bookingActivityClass = [s.bookingActivity]
    const bookingActivityClassRoom = [s.bookingActivityRumOpen]
    const bookingActivityClassTable = [s.bookingActivityBordOpen]
    const bookingActivityClassGolf = [s.bookingActivityGolfOpen]
    const separatorClass = [s.separatorClass]

    if (bookingmenuOpen) {
      openBookingClasses.push(s.open)
    }

    return (
      <div>
        {bookingmenuOpen && (
          <div className={s.overlayBooking}>
            <div
              className={bookingmenuOpen ? openBookingClasses.join(' ') : 'openBooking'}
            >
              <p
                className={bookingCloseButtonClass.join(' ')}
                onClick={() => toggleBookingMenu()}
              >
                {' '}
                <img src={Icons.close} />
              </p>
              <div className={bookingActivitiesClass.join(' ')}>
                <p
                  className={
                    activityMenu == 'rum'
                      ? bookingActivityClassRoom.join(' ')
                      : bookingActivityClass.join(' ')
                  }
                  onClick={() => toggleActivityMenu(bookingActivities[0].bookingTitle)}
                >
                  {' '}
                  {bookingActivities[0].bookingTitle}{' '}
                </p>
                <p className={separatorClass.join(' ')}> | </p>
                <p
                  className={
                    activityMenu == 'bord'
                      ? bookingActivityClassTable.join(' ')
                      : bookingActivityClass.join(' ')
                  }
                  onClick={() => toggleActivityMenu(bookingActivities[1].bookingTitle)}
                >
                  {' '}
                  {bookingActivities[1].bookingTitle}{' '}
                </p>
              </div>

              {activityMenu == 'rum' && (
                <>
                  <RoomBookingButton link={headerCtaLink} text={roomLinkText} />
                  <RoomBookingButton link={headerCtaLinkTwo} text={roomLinkTextTwo} />
                </>
              )}
              {activityMenu == 'bord' && <BookingTable />}
            </div>
          </div>
        )}
      </div>
    )
  }
}

interface IRoomBookingButton {
  link: string
  text: string
}

const RoomBookingButton = (props: IRoomBookingButton) => {
  const { link, text } = props

  return (
    <a href={link} target="_blank" className={s.roomBookingButton}>
      <span>{text}</span>
    </a>
  )
}

export const HeaderBookingMenu = (props: IHeaderCTA) => (
  <StaticQuery
    query={query}
    render={(data) => <HeaderBookingMenuComponent {...props} data={data} />}
  />
)
const query = graphql`
  query {
    wp {
      themeSettings {
        themeSettingsLanguages {
          themeSettingsLanguages {
            englishSettings {
              settingsHeader {
                headerCta {
                  headerCtaLink
                  headerCtaLinkTwo
                  roomLinkText
                  roomLinkTextTwo
                  headerCtaTitle
                  bookingActivities {
                    bookingTitle
                    bookingType
                  }
                }
              }
            }
            swedishSettings {
              settingsHeader {
                headerCta {
                  headerCtaLink
                  roomLinkText
                  headerCtaLinkTwo
                  roomLinkTextTwo
                  headerCtaTitle
                  bookingActivities {
                    bookingTitle
                    bookingType
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
