import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { IHeaderLogo } from '~models'
import s from './Header.module.scss'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import BackgroundImage from '~views/shared/components/BackgroundImage'
import { inject, observer } from 'mobx-react'
import { NavigationStore, WpmlStore } from '~stores'

interface Props {
  data: IHeaderLogo
  navigationStore?: NavigationStore
  wpmlStore?: WpmlStore
}

@inject('navigationStore', 'wpmlStore')
@observer
export class HeaderLogoComponent extends Component<Props> {
  render() {
    const { data, navigationStore, wpmlStore } = this.props
    const { lang } = wpmlStore
    const { themeSettingsLanguages } = data.wp.themeSettings.themeSettingsLanguages
    const { headerLogo: logo } =
      lang === 'en'
        ? themeSettingsLanguages.englishSettings.settingsHeader
        : themeSettingsLanguages.swedishSettings.settingsHeader
    return (
      <DynamicLink to="/" className={s.logo} onClick={navigationStore.closeMenu}>
        <BackgroundImage image={logo} objectFit="scale-down" />
      </DynamicLink>
    )
  }
}

export const HeaderLogo = (props: IHeaderLogo) => (
  <StaticQuery
    query={query}
    render={(data) => <HeaderLogoComponent {...props} data={data} />}
  />
)

const query = graphql`
  query {
    wp {
      themeSettings {
        themeSettingsLanguages {
          themeSettingsLanguages {
            englishSettings {
              settingsHeader {
                headerLogo {
                  localFile {
                    url
                    childImageSharp {
                      image: gatsbyImageData(
                        width: 132
                        quality: 50
                        placeholder: NONE
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
            swedishSettings {
              settingsHeader {
                headerLogo {
                  localFile {
                    url
                    childImageSharp {
                      image: gatsbyImageData(
                        width: 132
                        quality: 50
                        placeholder: NONE
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
