import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { ISingleWordpressPage } from '~models'
import { withStore } from '~libs/mobx'
import { Template } from '~views/templates'
import '~libs/graphql'
import { inject } from 'mobx-react'
import { ContextStore, WpmlStore } from '~stores'
import { Seo } from '~views/shared/components'
import { Header } from '~views/Header'
import { Footer } from '~views/Footer'
import { Cookie } from '~views/shared/blocks'
import { NewVisitorOverlay } from '~views/shared/blocks'
import { HeaderBookingMenu } from '~views/Header/HeaderBookingMenu'

interface PageTemplateProps {
  pageContext: any
  contextStore: ContextStore
  wpmlStore: WpmlStore
  data: {
    wordpressPage: ISingleWordpressPage
  }
  location: {
    host: string
    href: string
    pathname: string
  }
}

@inject('contextStore', 'wpmlStore')
class PageTemplate extends Component<PageTemplateProps> {
  componentDidMount() {
    // Set PageContext which are received by gatsby-node.js and make it globally accessible with mobx
    const { contextStore, wpmlStore, pageContext, location } = this.props
    const strippedPath = location.pathname.replace(/\/+$/, '')
    contextStore.context = pageContext
    contextStore.location = location
    contextStore.startpage = strippedPath === '' || strippedPath === '/en'
    wpmlStore.lang = pageContext.lang
  }

  renderTemplate() {
    const { sections } = this.props.data.wordpressPage.page

    return <Template sections={sections} />
  }

  render() {
    const { slug } = this.props.data.wordpressPage
    const { seo } = this.props.data.wordpressPage
    const { location } = this.props
    return (
      <>
        <Seo seo={seo} slug={slug} />
        <Header />
        <HeaderBookingMenu />
        {this.renderTemplate()}
        <Cookie host={location.host} />
        <NewVisitorOverlay host={location.host} />
        <Footer />
      </>
    )
  }
}

export default withStore(PageTemplate)

export const pageQuery = graphql`
  query ($id: String!) {
    wordpressPage: wpPage(id: { eq: $id }) {
      ...singleWordpressPageFragment
    }
  }
`
