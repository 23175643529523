import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { IHeaderLinksOverlay } from '~models'
import s from './Header.module.scss'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import { Container } from '~views/shared/components'
import { inject, observer } from 'mobx-react'
import { NavigationStore, WpmlStore } from '~stores'
import { Icons } from '~libs/assets'

interface Props {
  data: IHeaderLinksOverlay
  navigationStore?: NavigationStore
  wpmlStore?: WpmlStore
}

@inject('navigationStore', 'wpmlStore')
@observer
export class HeaderLinksOverlayComponent extends Component<Props> {
  renderSubmenuItem = (item, key) => {
    const { title, link } = item
    return (
      <div className={s.submenuItem} key={key}>
        <DynamicLink to={link?.link} onClick={this.props.navigationStore.closeMenu}>
          <h2 className={s.submenuItemTitle}>{title}</h2>
        </DynamicLink>
      </div>
    )
  }

  renderMenuItem = (item, key) => {
    const { openMenuItems, toggleSubmenu } = this.props.navigationStore
    const { title, link, hasSubmenu, submenu } = item
    let menuItemClasses = [s.menuItem]
    let menuItemTitleWrapperClasses = [s.menuItemTitleWrapper]
    let submenuClasses = [s.submenu]

    if (hasSubmenu === 'yes') {
      menuItemClasses.push(s.hasSubmenu)
      menuItemTitleWrapperClasses.push(s.hasSubmenu)
    }

    if (openMenuItems.includes(key)) {
      menuItemTitleWrapperClasses.push(s.open)
      submenuClasses.push(s.open)
    }

    return (
      <div className={menuItemClasses.join(' ')} key={key}>
        {hasSubmenu === 'no' && (
          <DynamicLink to={link?.link} onClick={this.props.navigationStore.closeMenu}>
            <h2 className={s.menuItemTitle}>{title}</h2>
          </DynamicLink>
        )}
        {hasSubmenu === 'yes' && (
          <div
            className={menuItemTitleWrapperClasses.join(' ')}
            onClick={() => toggleSubmenu(key)}
          >
            <h2 className={s.menuItemTitle}>{title}</h2>
            <img src={Icons.triangle_down} className={s.menuItemArrow} />
          </div>
        )}
        <div className={submenuClasses.join(' ')}>
          {submenu && submenu.map(this.renderSubmenuItem)}
        </div>
      </div>
    )
  }

  render() {
    const { data, wpmlStore } = this.props
    const { lang } = wpmlStore
    const { navigationLanguages } = data.wp.navigation.navigationLanguages
    const { mainMenu, ctaTitle, ctaLink, displayCta } =
      lang === 'en'
        ? navigationLanguages.englishSettings
        : navigationLanguages.swedishSettings

    return (
      <div className={s.overlayWrapper}>
        <Container noPadding className={s.menuItemWrapper}>
          {mainMenu?.map(this.renderMenuItem)}
          {displayCta === 'yes' && (
            <DynamicLink
              to={ctaLink}
              onClick={this.props.navigationStore.closeMenu}
              className={s.navigationCta}
            >
              <h2 className={s.menuItemTitle}>{ctaTitle}</h2>
            </DynamicLink>
          )}
        </Container>
      </div>
    )
  }
}

export const HeaderLinksOverlay = (props: IHeaderLinksOverlay) => (
  <StaticQuery
    query={query}
    render={(data) => <HeaderLinksOverlayComponent {...props} data={data} />}
  />
)

const query = graphql`
  query {
    wp {
      navigation {
        navigationLanguages {
          navigationLanguages {
            englishSettings {
              displayCta: displayCta
              ctaTitle: ctaTitle
              ctaLink: ctaLink
              mainMenu {
                title
                link {
                  ... on WpPage {
                    link
                    title
                  }
                }
                hasSubmenu
                submenu {
                  link {
                    ... on WpPage {
                      link
                      title
                    }
                  }
                  title
                }
              }
            }
            swedishSettings {
              displayCta: displayCta
              ctaTitle: ctaTitle
              ctaLink: ctaLink
              mainMenu {
                title
                link {
                  ... on WpPage {
                    link
                    title
                  }
                }
                hasSubmenu
                submenu {
                  link {
                    ... on WpPage {
                      link
                      title
                    }
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`
