import React, { Component } from 'react'
import { ISections } from '~models'
import {
  HeroSection,
  TextSection,
  ActivitiesSection,
  ImageCardsGridSection,
  BannerSection,
  ImageCardsRowSection,
  MapSection,
  ImageSection,
  InformationImageSection,
  TextAndImageSection,
  OfferSection,
  ActivityCalendarSection,
  BookingCTASection,
  StandardCTASection,
  AssetDownloadGridSection,
  HeroCTASection,
  ContactFormSection,
  ActivityGridSection,
} from '~sections'
export interface SectionsProps {
  sections?: ISections
}

export class Sections extends Component<SectionsProps> {
  render() {
    const { sections } = this.props

    const renderedSections = (sections || []).map((section, i) => {
      switch (section.__typename) {
        case 'WpPage_Page_Sections_Hero':
          return <HeroSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_Text':
          return <TextSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_Activities':
          return <ActivitiesSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_ImageCardsGrid':
          return <ImageCardsGridSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_BookingCta':
          return <BookingCTASection key={section.id} section={section} />
        case 'WpPage_Page_Sections_Banner':
          return <BannerSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_ImageCardsRow':
          return <ImageCardsRowSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_Map':
          return <MapSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_Image':
          return <ImageSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_InformationImage':
          return <InformationImageSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_TextAndImage':
          return <TextAndImageSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_Offer':
          return <OfferSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_ActivityCalendar':
          return <ActivityCalendarSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_StandardCta':
          return <StandardCTASection key={section.id} section={section} />
        case 'WpPage_Page_Sections_AssetDownloadGrid':
          return <AssetDownloadGridSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_HeroCta':
          return <HeroCTASection key={section.id} section={section} />
        case 'WpPage_Page_Sections_ContactForm':
          return <ContactFormSection key={section.id} section={section} />
        case 'WpPage_Page_Sections_ActivityGrid':
          return <ActivityGridSection key={section.id} section={section} />
        default:
          return <div key={i.toString()} />
      }
    })

    return renderedSections
  }
}
