export type TypeRequestAnimationFrame = (FrameRequestCallback) => number

export const getRequestAnimationFrame: () => TypeRequestAnimationFrame = () => {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    (window as any).mozRequestAnimationFrame ||
    (window as any).oRequestAnimationFrame ||
    (window as any).msRequestAnimationFrame ||
    function(callback) {
      return window.setTimeout(callback, 1000 / 60)
    }
  )
}

export const onAnimationFrame = (func: FrameRequestCallback) => {
  return getRequestAnimationFrame()(func)
}
