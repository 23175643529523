import React, { Component } from 'react'

interface OutsideAlerterProps extends React.HTMLProps<HTMLDivElement> {
  callback: () => any
}

export class OutsideAlerter extends Component<OutsideAlerterProps> {
  private wrapperRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    document.addEventListener('touchstart', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('touchstart', this.handleClickOutside)
  }

  handleClickOutside = e => {
    const { callback } = this.props
    if (this.wrapperRef.current && callback) {
      const clickedX = e.clientX
      const clickedY = e.clientY
      const rect = this.wrapperRef.current.getBoundingClientRect()
      const targetElTop = rect.top
      const targetElBottom = rect.top + rect.height
      const targetElLeft = rect.left
      const targetElRight = rect.left + rect.width

      if (
        clickedX < targetElLeft ||
        clickedX > targetElRight ||
        clickedY < targetElTop ||
        clickedY > targetElBottom
      ) {
        callback()
      }
    }
  }

  render() {
    const { children, callback, ...rest } = this.props
    return (
      <div ref={this.wrapperRef} {...rest}>
        {children}
      </div>
    )
  }
}
