import { observable, action, computed } from 'mobx'
import { Store } from './Store'

export class BookingStore {
  rootStore: Store

  constructor(rootStore: Store) {
    this.rootStore = rootStore
  }

  dateToday: Date = new Date()

  @observable
  checkInDate: Date = new Date()

  @observable
  checkOutDate: Date

  @observable
  minCheckOutDate: Date

  @observable
  maxCheckInDate: Date

  @observable
  numberOfAdults: number = 1

  @observable
  numberOfChildren: number = 0

  @observable
  numberOfInfants: number = 0

  @observable
  totalNumberOfGuests: number = 1

  @observable
  discountCode: string = ''

  defaultAP: string = 'https://book.villabaro.se/'

  lang: string = this.rootStore && this.rootStore.wpmlStore.lang ? 'en/' : '/'

  hotelInfo: string = 'villa-baro/hotell/booking/rooms/?'

  arrivalDate: string = ''

  departureDate: string = ''

  @observable
  initiateScroll: () => void = null

  @observable
  scrollTarget: number = 0

  @observable
  checkInOpen: boolean = false

  @observable
  checkInOpenNavbar: boolean = false

  @observable
  checkOutOpen: boolean = false

  @observable
  checkOutOpenNavbar: boolean = false

  @observable
  guestPickerOpen: boolean = false

  @observable
  guestPickerOpenNavbar: boolean = false

  @computed
  get ctaOpen() {
    return this.checkInOpen || this.checkOutOpen || this.guestPickerOpen
  }

  @action
  closeCTA = () => {
    this.checkInOpen = false
    this.checkOutOpen = false
    this.guestPickerOpen = false
    this.guestPickerOpenNavbar = false
  }

  @action
  toggleCheckIn = () => {
    if (this.checkInOpen) {
      setTimeout(() => (this.checkInOpen = false), 100)
    } else {
      this.checkInOpen = !this.checkInOpen
    }
  }

  @action
  toggleCheckInNavbar = () => {
    if (this.checkInOpenNavbar) {
      setTimeout(() => (this.checkInOpenNavbar = false), 100)
    } else {
      this.checkInOpenNavbar = !this.checkInOpenNavbar
    }
  }

  @action
  toggleCheckOut = () => {
    if (this.checkOutOpen) {
      setTimeout(() => (this.checkOutOpen = false), 100)
    } else {
      this.checkOutOpen = !this.checkOutOpen
    }
  }

  @action
  toggleCheckOutNavbar = () => {
    if (this.checkOutOpenNavbar) {
      setTimeout(() => (this.checkOutOpenNavbar = false), 100)
    } else {
      this.checkOutOpenNavbar = !this.checkOutOpenNavbar
    }
  }

  @action
  toggleGuestPicker = () => {
    this.guestPickerOpen = !this.guestPickerOpen
  }
  @action
  toggleGuestPickerNavbar = () => {
    this.guestPickerOpenNavbar = !this.guestPickerOpenNavbar
  }
  @action
  closeGuestPicker = () => {
    this.guestPickerOpen = false
  }
  @action
  closeGuestPickerNavbar = () => {
    this.guestPickerOpenNavbar = false
  }

  @action
  setMinCheckOutDate = (date) => {
    this.minCheckOutDate = new Date(date)
    return this.minCheckOutDate.setDate(date.getDate() + 1)
  }

  @action
  setMaxCheckInDate = (date) => {
    if (date !== this.dateToday) {
      this.maxCheckInDate = new Date(date)
      return this.maxCheckInDate.setDate(date.getDate() - 1)
    }
  }

  @action
  incrementGuestOfType = (type) => {
    this.totalNumberOfGuests++
    switch (type) {
      case 'adult':
        this.numberOfAdults++
        break
      case 'child':
        this.numberOfChildren++
        break
      case 'infant':
        this.numberOfInfants++
        break
    }
  }

  @action
  decrementGuestOfType = (type) => {
    this.totalNumberOfGuests > 1 ? this.totalNumberOfGuests-- : 1
    switch (type) {
      case 'adult':
        this.numberOfAdults > 1 ? this.numberOfAdults-- : 1
        break
      case 'child':
        this.numberOfChildren > 0 ? this.numberOfChildren-- : 0
        break
      case 'infant':
        this.numberOfInfants > 0 ? this.numberOfInfants-- : 0
        break
    }
  }

  formatDateToSlimFormat = (date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  createQuerySubString = (prop, value, prefixChar: boolean) => {
    let resultString = ''
    resultString = prefixChar ? (resultString += '&') : ''
    if (typeof value === 'number') {
      value = value.toString()
    }

    if (value.length > 0 && prop.length > 0) {
      resultString += prop + '=' + value
    }
    return resultString
  }

  setQueryString = () => {
    const {
      defaultAP,
      hotelInfo,
      arrivalDate,
      departureDate,
      numberOfAdults,
      numberOfChildren,
      numberOfInfants,
      discountCode,
    } = this

    const lang = this.rootStore && this.rootStore.wpmlStore?.lang === 'en' ? 'en/' : ''

    let resultString = ''

    resultString += defaultAP + lang + hotelInfo

    resultString += this.createQuerySubString('ArrivalDate', arrivalDate, true)

    resultString += this.createQuerySubString('DepartureDate', departureDate, true)

    resultString += this.createQuerySubString('Adults', numberOfAdults, true)

    resultString += this.createQuerySubString('Children1', numberOfChildren, true)

    resultString += this.createQuerySubString('Children2', numberOfInfants, true)

    resultString += this.createQuerySubString('Campaigncode', discountCode, true)

    return resultString
  }

  @action
  applyFilter = () => {
    let queryString = this.setQueryString()
    typeof window !== 'undefined' && window.open(queryString)
  }
}
