import React, { Component } from 'react'
import s from './ImageSlider.module.scss'
import { IImage } from '~models'
import { Icons } from '~libs/assets'
import BackgroundImage from '../BackgroundImage'

interface Slide {
  image: IImage
}

export interface ImageSliderProps {
  ref?: (ref: ImageSlider) => void
  slides: Slide[]
  renderIndicator?(
    slides: Slide[],
    current: number,
    next: () => void,
    previous: () => void,
    goToIndex: (index: number) => void,
  )
  slideTime: number
  className?: string
}

interface State {
  currentSlide: number
  timer: any
}

export class ImageSlider extends Component<ImageSliderProps, State> {
  state = {
    currentSlide: 0,
    timer: null,
  }

  componentDidMount() {
    this.startTimer()
  }

  startTimer = () => {
    const { slideTime } = this.props
    const timer = setInterval(() => this.next(), slideTime)
    this.setState({ timer })
  }

  clearTimer = () => {
    const { timer } = this.state
    clearInterval(timer)
  }

  resetTimer = () => {
    this.clearTimer()
    this.startTimer()
  }

  componentWillUnmount() {
    this.clearTimer()
  }

  next = () => {
    const { currentSlide } = this.state
    const { slides } = this.props

    const currentSlideIndex = slides.length > currentSlide + 1 ? currentSlide + 1 : 0

    this.setState({
      currentSlide: currentSlideIndex,
    })

    this.resetTimer()
  }

  previous = () => {
    const { currentSlide } = this.state
    const { slides } = this.props

    const currentSlideIndex = currentSlide > 0 ? currentSlide - 1 : slides.length - 1

    this.setState({
      currentSlide: currentSlideIndex,
    })

    this.resetTimer()
  }

  goToIndex = (index: number) => {
    const { slides } = this.props

    if (index < slides.length && index >= 0) {
      this.setState({ currentSlide: index })
    }

    this.resetTimer()
  }

  renderArrows = () => (
    <div className={s.arrows}>
      <img
        src={Icons.simple_arrow_left}
        className={`${s.arrow} ${s.left}`}
        onClick={() => this.previous()}
      />
      <img
        src={Icons.simple_arrow_right}
        className={`${s.arrow} ${s.right}`}
        onClick={() => this.next()}
      />
    </div>
  )

  renderIndicator = () => {
    const { slides } = this.props
    const { currentSlide } = this.state

    return (
      <div className={s.dots}>
        {slides.map((_slide, i) => {
          const dotClasses = [s.dot]
          if (currentSlide === i) dotClasses.push(s.active)
          return (
            <div
              key={i}
              className={dotClasses.join(' ')}
              onClick={(e) => {
                e.stopPropagation()
                this.goToIndex(i)
              }}
            />
          )
        })}
      </div>
    )
  }

  render() {
    const { slides, className } = this.props
    const { currentSlide } = this.state

    const wrapperClasses = [s.wrapper]
    if (className) {
      wrapperClasses.push(className)
    }

    return (
      <div className={wrapperClasses.join(' ')}>
        {slides &&
          slides.map((slide, i) => {
            const sliderClasses = [s.slide]
            if (i === currentSlide) {
              sliderClasses.push(s.active)
              sliderClasses.push(s.prevoius)
            }
            return (
              <div className={sliderClasses.join(' ')} key={i}>
                <BackgroundImage image={slide.image} />
              </div>
            )
          })}
        {this.renderIndicator()}
        {this.renderArrows()}
      </div>
    )
  }
}
