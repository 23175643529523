import React, { Component, CSSProperties } from 'react'
import { ISections } from '~models'
import { Sections } from '~views/Sections'
import { NavigationStore } from '~stores'
import { inject, observer } from 'mobx-react'

export interface TemplateProps {
  sections: ISections
  navigationStore?: NavigationStore
}

@inject('navigationStore')
@observer
export class Template extends Component<TemplateProps> {
  render() {
    const { sections, navigationStore } = this.props
    const { menuOpen, navigationMenuOpenPageYOffset } = navigationStore

    let style: CSSProperties = {}

    if (menuOpen) {
      style = { transform: `translate3d(0, ${-navigationMenuOpenPageYOffset}px, 0)` }
    } else {
      style = { transform: 'translate3d(0, 0, 0)' }
    }

    return (
      <div className="sectionsContainer" style={style}>
        <Sections sections={sections} />
      </div>
    )
  }
}
