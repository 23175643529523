import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { WpmlStore } from '~stores'
import { inject, observer } from 'mobx-react'

export interface DynamicLinkProps {
  wordpressBaseUrl: string
  to: string
  [x: string]: any
  wpmlStore?: WpmlStore
}

@inject('wpmlStore')
@observer
class DLink extends React.Component<DynamicLinkProps> {
  render() {
    const { to, wordpressBaseUrl, className, wpmlStore, ...rest } = this.props

    if (to === null) {
      return <a {...rest} />
    }

    if (to && to.toString().startsWith('/wp-content')) {
      return <a href={wordpressBaseUrl} target="_blank" className={className} {...rest} />
    }

    if (to && to.toString().includes(process.env.GATSBY_WP_BASE_URL)) {
      let redirectUrl = to.replace(
        process.env.GATSBY_WP_BASE_URL,
        process.env.GATSBY_WP_FRONTEND_URL,
      )
      return <Link to={redirectUrl} className={className} {...rest} />
    }

    if (to && to.toString().startsWith('/')) {
      return <Link to={to} className={className} {...rest} />
    }

    return <a href={to} className={className} {...rest} target="_blank" />
  }
}

const query = graphql`
  query {
    wp {
      generalSettings {
        url
      }
    }
  }
`

export const DynamicLink = ({ to, ...rest }) => (
  <StaticQuery
    query={query}
    render={data => {
      return <DLink style={{ 'text-decoration': 'none' }} to={to} {...rest} />
    }}
  />
)
