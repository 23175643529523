import React from 'react'
import { IContactFormSection } from '~models'
import s from './ContactForm.module.scss'
import { ContactFormStore, WpmlStore } from '~stores'
import { inject, observer } from 'mobx-react'
import { Container, Input, Row, Checkbox } from '~views/shared/components'
import { ContactFormDatePicker } from './ContactFormDatePicker'
import { graphql, StaticQuery } from 'gatsby'
import { IContactForm } from '~models'

interface Props {
  section?: IContactFormSection
  data: IContactForm
  contactFormStore?: ContactFormStore
  wpmlStore?: WpmlStore
}
@inject('contactFormStore', 'wpmlStore')
@observer
class _ContactFormSection extends React.Component<Props> {
  componentDidMount() {
    const { data, wpmlStore } = this.props
    const { lang } = wpmlStore
    const { themeSettingsLanguages } = data.wp.themeSettings.themeSettingsLanguages
    this.props.contactFormStore.roomType =
      lang === 'en'
        ? themeSettingsLanguages.englishSettings.settingsContactForm.roomTypes.singleroom
        : themeSettingsLanguages.swedishSettings.settingsContactForm.roomTypes.singleroom
  }

  roomTypeChanged = (value, room_types) => {
    value != this.props.contactFormStore.roomType &&
    this.props.contactFormStore.roomType === room_types.singleroom
      ? (this.props.contactFormStore.roomType = room_types.doubleroom)
      : (this.props.contactFormStore.roomType = room_types.singleroom)
  }

  onActivityClicked = activity => {
    const i = this.props.contactFormStore.activities?.indexOf(activity.activityTitle)
    i > -1
      ? this.props.contactFormStore.activities?.splice(i, 1)
      : this.props.contactFormStore.activities?.push(activity.activityTitle)
  }

  renderActivities = activity => {
    const months = [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ]
    const { checkInDate, checkOutDate } = this.props.contactFormStore
    return activity.months[months[checkInDate.getMonth()]] ||
      activity.months[months[checkOutDate?.getMonth()]] ? (
      <div className={s.checkboxWrapper} key={activity.activity_title}>
        <Checkbox
          selected={
            this.props.contactFormStore.activities?.indexOf(activity.activityTitle) > -1
          }
          onChange={() => this.onActivityClicked(activity)}
        ></Checkbox>
        <h3 key={`text-${activity}`} className={s.formText}>
          {activity.activityTitle}
        </h3>
      </div>
    ) : null
  }
  render() {
    const { wpmlStore, contactFormStore, data } = this.props
    const { lang } = wpmlStore
    const { themeSettingsLanguages } = data.wp.themeSettings.themeSettingsLanguages
    const { settingsContactForm } =
      lang === 'en'
        ? themeSettingsLanguages.englishSettings
        : themeSettingsLanguages.swedishSettings
    const {
      cfTitle,
      cfSubtitle,
      cfRoomTitle,
      cfActivityTitle,
      cfTermsText,
      cfSubmitButtonText,
      activities,
      roomTypes,
    } = settingsContactForm

    const {
      cfContactPerson,
      cfMessage,
      cfBusiness,
      cfEmail,
      cfNumberOfGuests,
      cfPhoneNumber,
    } = settingsContactForm.contactFields

    const {
      agreeTerms,
      roomType,
      email,
      phoneNr,
      message,
      contactPerson,
      business,
      numberOfGuests,
      errorText,
    } = contactFormStore

    contactFormStore.url = process.env.GATSBY_WP_BASE_URL
    contactFormStore.lang = lang

    const activityList = []
    for (let index in activities) {
      activityList.push(activities[index])
    }

    return (
      <section>
        <Container noPadding className={s.container}>
          <Row noMargin>
            <form onSubmit={contactFormStore.onSubmit}>
              <h2 className={s.title}>{cfTitle}</h2>
              <div className={s.formContainer}>
                <div className={s.leftColumn}>
                  <h3 className={s.formText}>{cfSubtitle}</h3>
                  <ContactFormDatePicker checkIn checkOut />
                  <h3 className={s.textField}>{cfRoomTitle}</h3>
                  <div className={s.roomWrapper}>
                    <Input
                      type="radio"
                      value={roomType}
                      values={[roomTypes.doubleroom, roomTypes.singleroom]}
                      onChange={value => this.roomTypeChanged(value, roomTypes)}
                    ></Input>
                  </div>
                  <h2 className={s.textField}>{cfActivityTitle}</h2>
                  <div className={s.activity}>
                    {activityList &&
                      activityList.map(activity => this.renderActivities(activity))}
                  </div>
                  <Input
                    type="textarea"
                    placeholder={cfMessage.placeholder}
                    value={message}
                    onChange={text => (contactFormStore.message = text)}
                  ></Input>
                </div>
                <div className={s.column}>
                  <div className={s.textFieldWrapper}>
                    <Input
                      type="text"
                      placeholder={cfBusiness.placeholder}
                      value={business}
                      onChange={text => (contactFormStore.business = text)}
                    ></Input>
                    <Input
                      type="text"
                      placeholder={cfContactPerson.placeholder}
                      value={contactPerson}
                      onChange={text => (contactFormStore.contactPerson = text)}
                    ></Input>
                    <Input
                      type="email"
                      placeholder={cfEmail.placeholder}
                      value={email}
                      onChange={text => (contactFormStore.email = text)}
                    ></Input>
                    <Input
                      type="tel"
                      placeholder={cfPhoneNumber.placeholder}
                      value={phoneNr}
                      onChange={text => (contactFormStore.phoneNr = text)}
                    ></Input>
                    <Input
                      type="number"
                      placeholder={cfNumberOfGuests.placeholder}
                      value={numberOfGuests}
                      onChange={text => (contactFormStore.numberOfGuests = text)}
                    ></Input>
                  </div>
                  <div className={s.agreeCheckbox}>
                    <Checkbox
                      selected={agreeTerms ? true : false}
                      onChange={chosen => {
                        contactFormStore.agreeTerms = chosen
                      }}
                    ></Checkbox>
                    <h3 className={s.smallText}>{cfTermsText}</h3>
                  </div>
                  <button className={s.submitButton} type="submit" onClick={() => {}}>
                    {cfSubmitButtonText}
                  </button>
                  <div className={s.smallText} id="errortext">
                    <br />
                    {errorText}
                  </div>
                </div>
              </div>
            </form>
          </Row>
        </Container>
      </section>
    )
  }
}

export const ContactFormSection: any = (props: IContactFormSection) => (
  <StaticQuery
    query={query}
    render={data => <_ContactFormSection {...props} data={data} />}
  />
)
const query = graphql`
  query {
    wp {
      themeSettings {
        themeSettingsLanguages {
          themeSettingsLanguages {
            englishSettings {
              settingsContactForm {
                cfTitle
                cfSubtitle
                cfRoomTitle
                cfActivityTitle
                cfTermsText
                cfSubmitButtonText
                contactFields {
                  cfBusiness {
                    placeholder
                  }
                  cfContactPerson {
                    placeholder
                  }
                  cfEmail {
                    placeholder
                  }
                  cfMessage {
                    placeholder
                  }
                  cfNumberOfGuests {
                    placeholder
                  }
                  cfPhoneNumber {
                    placeholder
                  }
                }
                activities {
                  activityTitle
                  months {
                    jan
                    feb
                    mar
                    apr
                    may
                    jun
                    jul
                    aug
                    sep
                    oct
                    nov
                    dec
                  }
                }
                roomTypes {
                  singleroom
                  doubleroom
                }
              }
            }
            swedishSettings {
              settingsContactForm {
                cfTitle
                cfSubtitle
                cfRoomTitle
                cfActivityTitle
                cfTermsText
                cfSubmitButtonText
                contactFields {
                  cfBusiness {
                    placeholder
                  }
                  cfContactPerson {
                    placeholder
                  }
                  cfEmail {
                    placeholder
                  }
                  cfMessage {
                    placeholder
                  }
                  cfNumberOfGuests {
                    placeholder
                  }
                  cfPhoneNumber {
                    placeholder
                  }
                }
                activities {
                  activityTitle
                  months {
                    jan
                    feb
                    mar
                    apr
                    may
                    jun
                    jul
                    aug
                    sep
                    oct
                    nov
                    dec
                  }
                }
                roomTypes {
                  singleroom
                  doubleroom
                }
              }
            }
          }
        }
      }
    }
  }
`
