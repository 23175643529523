import React, { createRef } from 'react'
import { IHeroSection } from '~models'
import s from './Hero.module.scss'
import { Container, SmoothScroll } from '~views/shared/components'
import { HeroStore, NavigationStore, ContextStore, UIStore } from '~stores'
import { inject, observer } from 'mobx-react'
import BackgroundImage from '~views/shared/components/BackgroundImage'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import 'swiper/css/bundle'
import 'swiper/css'
import 'swiper/css/pagination'

SwiperCore.use([Pagination, Autoplay])
interface Props {
  section: IHeroSection
  heroStore?: HeroStore
  uiStore?: UIStore
  navigationStore?: NavigationStore
  contextStore?: ContextStore
}

@inject('heroStore', 'navigationStore', 'contextStore', 'uiStore')
@observer
export class HeroSection extends React.Component<Props> {
  private currentSection = createRef<HTMLDivElement>()
  private smoothScroller = createRef<SmoothScroll>()

  initiateSmoothScroll = () => {
    if (this.currentSection && this.currentSection.current) {
      const offsetTop = this.currentSection.current.offsetTop
      const clientHeight = this.currentSection.current.clientHeight
      const navBarHeight = this.props.navigationStore.headerHeight
      this.props.heroStore.scrollTarget = offsetTop + clientHeight - navBarHeight
    }
    this.smoothScroller.current.initAnimation()
  }

  render() {
    const { section, heroStore, uiStore, contextStore } = this.props
    const { scrollTarget } = heroStore
    const {
      imageOrSliderOrVideo,
      backgroundImage,
      videoUrl,
      slides,
      title,
      text,
      textColor,
      imageOverlay,
    } = section
    const { startpage } = contextStore

    let sectionClasses = []
    let containerClasses = [s.container]
    let backgroundImageClasses = [s.backgroundImage]
    let textWrapperClasses = [s.textWrapper]
    let headingClasses = []

    if (startpage) {
      sectionClasses.push(s.startpage)
    } else {
      headingClasses.push('medium', 'subpage')
    }

    if (textColor === 'black') {
      containerClasses.push(s.black)
    }

    if (imageOverlay) {
      sectionClasses.push(s.mask)
    }

    // In production builds, the window width is 0 for a very short
    // moment, causing an image to flicker on the screen briefly.
    const isLoaded = uiStore.windowWidth != 0

    const showImage =
      isLoaded &&
      (imageOrSliderOrVideo === 'image' ||
        (uiStore.windowWidth <= 500 &&
          (imageOrSliderOrVideo === 'slider' || imageOrSliderOrVideo === 'video')))

    const showSlider =
      isLoaded &&
      imageOrSliderOrVideo === 'slider' &&
      slides.length > 0 &&
      uiStore.windowWidth > 500

    const showVideo =
      isLoaded && imageOrSliderOrVideo === 'video' && uiStore.windowWidth > 500

    const showText = showImage || showVideo

    textWrapperClasses.push(s.fadeIn)

    if (showVideo) {
      backgroundImageClasses.push(s.fadeIn)
    }

    return (
      <section
        data-sectionname="Hero"
        ref={this.currentSection}
        className={sectionClasses.join(' ')}
      >
        {showSlider && (
          <Swiper
            className={s.swiperContainer}
            spaceBetween={0}
            slidesPerView={1}
            speed={1000}
            loop={true}
            pagination={{
              clickable: true,
              clickableClass: `${s.swiperPaginationBullets}`,
              bulletActiveClass: `${s.swiperPaginationBulletActive}`,
            }}
            autoplay={{ delay: 7000 }}
          >
            {slides &&
              slides.map((slide, index) => {
                return (
                  <SwiperSlide key={index} className={s.swiperSlide}>
                    <SmoothScroll
                      duration={1000}
                      target={scrollTarget}
                      ref={this.smoothScroller}
                    />
                    {backgroundImage && (
                      <div className={backgroundImageClasses.join(' ')}>
                        <BackgroundImage image={slide.image} loading="eager" />
                      </div>
                    )}
                    <Container
                      className={[
                        s.container,
                        slide.textColor === 'black' ? s.black : '',
                      ].join(' ')}
                    >
                      <div className={textWrapperClasses.join(' ')}>
                        {slide.title && (
                          <div className={s.title}>
                            <h1 className={headingClasses.join(' ')}>{slide.title}</h1>
                          </div>
                        )}
                        {slide.text && (
                          <div className={s.text}>
                            <p className="ingress">{slide.text}</p>
                          </div>
                        )}
                      </div>
                    </Container>
                    <div className="swiper-pagination swiper-pagination-bullets" />
                  </SwiperSlide>
                )
              })}
            ...
          </Swiper>
        )}
        {showImage && (
          <>
            <SmoothScroll
              duration={1000}
              target={scrollTarget}
              ref={this.smoothScroller}
            />
            {backgroundImage && (
              <div className={backgroundImageClasses.join(' ')}>
                <BackgroundImage image={backgroundImage} loading="eager" />
              </div>
            )}
          </>
        )}
        {showVideo && (
          <>
            {!!videoUrl && (
              <div className={s.videoWrapper}>
                <video autoPlay controls={false} muted loop playsInline>
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </div>
            )}
          </>
        )}
        {showText && (
          <>
            <Container className={containerClasses.join(' ')}>
              <div className={textWrapperClasses.join(' ')}>
                {title && (
                  <div className={s.title}>
                    <h1 className={headingClasses.join(' ')}>{title}</h1>
                  </div>
                )}
                {text && (
                  <div className={s.text}>
                    <p className="ingress">{text}</p>
                  </div>
                )}
              </div>
            </Container>
          </>
        )}
      </section>
    )
  }
}
