import React, { createRef } from 'react'
import s from '../BookingCTA.module.scss'
import { Container, DynamicLink, SmoothScroll } from '~views/shared/components'
import { BookingStore, ContextStore, WpmlStore, UIStore } from '~stores'
import { inject, observer } from 'mobx-react'
import { getDevice } from '~libs/utils'
import { IHeroCTASection } from '~models'
import WidgetCTA from './WidgetCTA'

interface Props {
  contextStore?: ContextStore
  bookingStore?: BookingStore
  wpmlStore?: WpmlStore
  uiStore?: UIStore
  section: IHeroCTASection
}

@inject('contextStore', 'bookingStore', 'wpmlStore', 'uiStore')
@observer
export class HeroCTASection extends React.Component<Props> {
  private currentSection = createRef<HTMLDivElement>()
  private smoothScroller = createRef<SmoothScroll>()

  componentDidMount() {
    const { bookingStore } = this.props
    bookingStore.initiateScroll = this.initiateSmoothScroll
  }

  initiateSmoothScroll = () => {
    if (this.currentSection && this.currentSection.current) {
      const offsetTop = this.currentSection.current.offsetTop
      const scrollHeight = this.currentSection.current.scrollHeight
      this.props.bookingStore.scrollTarget = offsetTop - scrollHeight
      if (
        (getDevice() == 'desktop' || getDevice() == 'mac' || getDevice() == 'windows') &&
        window.pageYOffset < scrollHeight
      ) {
        this.smoothScroller.current.initAnimation()
      }
    }
  }

  renderButton = (buttonTitle, buttonLink) => (
    <DynamicLink className={s.heroCTAButton} to={buttonLink}>
      {buttonTitle}
    </DynamicLink>
  )

  render() {
    const { contextStore, bookingStore, section, uiStore } = this.props
    const { startpage } = contextStore
    const { scrollTarget } = bookingStore
    const { displayGiftCardButton, buttonTitle, buttonLink } = section
    const displayButton =
      displayGiftCardButton === 'yes' && uiStore.isAboveOrEqualToBreakpoint('xxl')

    const ctaSectionClasses = []
    const ctaContainerClasses = [s.ctaContainer]
    const ctaWrapperClasses = [s.ctaWrapper, s.hero]

    if (startpage) {
      ctaSectionClasses.push(s.startpage)
    }

    if (bookingStore.ctaOpen) {
      ctaWrapperClasses.push(s.open)
    }

    if (displayButton) {
      ctaContainerClasses.push(s.hasButton)
      ctaWrapperClasses.push(s.hasButton)
    }

    return (
      <section
        data-sectionname="BookingCTA"
        className={ctaSectionClasses.join(' ')}
        ref={this.currentSection}
      >
        <SmoothScroll duration={1000} ref={this.smoothScroller} target={scrollTarget} />
        <Container
          noPadding
          fluid={displayButton}
          className={ctaContainerClasses.join(' ')}
        >
          <div className={ctaWrapperClasses.join(' ')}>
            <WidgetCTA />
          </div>
          {displayButton && (
            <DynamicLink className={s.heroCTAButton} to={buttonLink}>
              {buttonTitle}
            </DynamicLink>
          )}
        </Container>
      </section>
    )
  }
}
