import {
  HeroStore,
  UIStore,
  ContextStore,
  NavigationStore,
  ActivityFilterStore,
  BookingStore,
  WpmlStore,
  ContactFormStore,
} from '~stores'

let store = null

export class Store {
  uiStore: UIStore
  contextStore: ContextStore
  heroStore: HeroStore
  navigationStore: NavigationStore
  activityFilterStore: ActivityFilterStore
  bookingStore: BookingStore
  wpmlStore: WpmlStore
  contactFormStore: ContactFormStore

  constructor() {
    this.uiStore = new UIStore()
    this.contextStore = new ContextStore()
    this.wpmlStore = new WpmlStore()
    this.heroStore = new HeroStore()
    this.navigationStore = new NavigationStore()
    this.activityFilterStore = new ActivityFilterStore()
    this.bookingStore = new BookingStore(this)
    this.contactFormStore = new ContactFormStore()
  }

  onMount = () => {
    this.uiStore.onMount()
  }

  onUnmount = () => {
    this.uiStore.onUnmount()
  }
}

export const initStore = () => {
  store = store != null ? store : new Store()
  return store
}
