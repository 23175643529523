import React, { Component } from 'react'
import styles from './styles.module.scss'

export interface CheckboxProps {
  selected?: boolean
  onChange?: (boolean) => void
}

export class Checkbox extends Component<CheckboxProps> {
  onChange = () => {
    const { selected, onChange } = this.props
    if (onChange) {
      onChange(!selected)
    }
  }

  render() {
    const { selected } = this.props
    return (
      <label className={styles.wrapper}>
        <input type="checkbox" checked={selected} onChange={this.onChange} />
        <span></span>
      </label>
    )
  }
}
