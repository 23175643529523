import * as React from 'react'

interface SmoothScrollProps {
  duration: number
  target: number
  children?: any
}

interface SmoothScrollState {
  isAnimating: boolean
  scrollOffsetInitial: number
  animationStartTime?: number
}

export class SmoothScroll extends React.Component<SmoothScrollProps, SmoothScrollState> {
  state = {
    isAnimating: false,
    scrollOffsetInitial: 0,
    animationStartTime: undefined,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const { isAnimating } = this.state
    if (!isAnimating) {
      this.setState({
        scrollOffsetInitial: window.pageYOffset,
      })
    }
  }

  initAnimation = () => {
    if (this.state.isAnimating) {
      return
    }
    this.setState({
      isAnimating: true,
      animationStartTime: performance.now(),
    })
    this.animate()
  }

  animate = () => {
    const easing = t => {
      return t < 0.5 ? 16 * t ** 5 : 1 + 16 * (--t) ** 5
    }

    requestAnimationFrame(() => {
      const { duration, target } = this.props
      const { animationStartTime, scrollOffsetInitial } = this.state

      const now = performance.now()
      const ellapsed = now - animationStartTime
      const scrollDelta = target - scrollOffsetInitial
      const easedTime = easing(Math.min(1, ellapsed / duration))
      const scrollOffset = scrollOffsetInitial + scrollDelta * easedTime

      window.scrollTo(0, scrollOffset)

      if (ellapsed < duration) {
        this.animate()
      } else {
        this.setState({ animationStartTime: undefined, isAnimating: false })
      }
    })
  }

  render() {
    const { children } = this.props
    return children ? <div className="smoothscroller">{children}</div> : true
  }
}
