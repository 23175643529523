import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { graphql, StaticQuery } from 'gatsby'
import { IFooterLogo } from '~models'
import s from './Footer.module.scss'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import BackgroundImage from '~views/shared/components/BackgroundImage'
import { enableBodyScrolling } from '~libs/utils'
import { WpmlStore } from '~stores'

interface Props {
  data: IFooterLogo
  wpmlStore?: WpmlStore
}

@inject('wpmlStore')
@observer
export class FooterLogoComponent extends Component<Props> {
  linkClick = () => {
    enableBodyScrolling()
  }
  render() {
    const { data, wpmlStore } = this.props
    const { lang } = wpmlStore
    const { themeSettingsLanguages } = data.wp.themeSettings.themeSettingsLanguages
    const { footerLogo: logo } =
      lang === 'en'
        ? themeSettingsLanguages.englishSettings.settingsFooter
        : themeSettingsLanguages.swedishSettings.settingsFooter

    return (
      <div className={s.footerLogoWrapper}>
        <DynamicLink to="/" className={s.logo} onClick={this.linkClick}>
          <BackgroundImage image={logo} objectFit="scale-down" />
        </DynamicLink>
      </div>
    )
  }
}

export const FooterLogo = (props: IFooterLogo) => (
  <StaticQuery
    query={query}
    render={(data) => <FooterLogoComponent {...props} data={data} />}
  />
)

const query = graphql`
  query {
    wp {
      themeSettings {
        themeSettingsLanguages {
          themeSettingsLanguages {
            englishSettings {
              settingsFooter {
                footerLogo {
                  localFile {
                    url
                    childImageSharp {
                      image: gatsbyImageData(
                        width: 132
                        quality: 50
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
            swedishSettings {
              settingsFooter {
                footerLogo {
                  localFile {
                    url
                    childImageSharp {
                      image: gatsbyImageData(
                        width: 132
                        quality: 50
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
