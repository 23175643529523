import React from 'react'
import s from './Image.module.scss'
import { Container, ImageSlider } from '~views/shared/components'
import { IImageSection } from '~models'
import BackgroundImage from '~views/shared/components/BackgroundImage'

interface Props {
  section: IImageSection
}
export class ImageSection extends React.Component<Props> {
  render() {
    const { section } = this.props
    const { image, imageCaption, sliderImages, imageOrSlider } = section

    return (
      <section data-sectionname="Image">
        <Container noPadding fluid className={s.customContainer}>
          {imageOrSlider === 'image' && image && (
            <>
              <BackgroundImage image={image} className={s.image} objectFit={'contain'} />
              {imageCaption && <div className={s.captionWrapper}>{imageCaption}</div>}
            </>
          )}
          {imageOrSlider === 'slider' && sliderImages && sliderImages.length > 0 && (
            <ImageSlider slides={sliderImages} slideTime={8000} className={s.image} />
          )}
        </Container>
      </section>
    )
  }
}
