import React, { useContext, useEffect } from 'react'
import s from './WidgetCTA.module.scss'
import { observer, MobXProviderContext } from 'mobx-react'

const WidgetCTAColumns = () => {
  const store = useContext(MobXProviderContext)

  const language = store.wpmlStore.lang

  useEffect(() => {
    const config = {
      channelId: 'c856861a-d0b9-44b9-8e01-021e0dc29af9',
      widgetLayout: 'Standard2Columns',
      WidgetContainerId: 'widgetContainerColumn',
      promoCode: 'false',
      corpCode: 'false',
      agentCode: 'false',
      openOption: 'newwindow',
      loginLink: 'false',
      currency: 'SEK',
      languageCulture: language == 'en' ? 'en-GB' : 'sv-SE',
      accommodationTypePicker: 'false',
      allPackages: 'false',
      type: 'single_property',
    }

    const widgetScript = document.createElement('script')
    widgetScript.type = 'text/javascript'
    widgetScript.async = true
    widgetScript.src =
      'https://online.bookvisit.com/Content/widget/accommodation_widget.bundle.js'

    // @ts-ignore
    widgetScript.onload = () => {
      if (BV) {
        BV.SPWidget(config)
      }
    }

    document.head.appendChild(widgetScript)
  }, [])

  return <div className={s.widgetContainerColumn} id="widgetContainerColumn"></div>
}

export default observer(WidgetCTAColumns)
