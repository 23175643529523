import React from 'react'
import s from './Map.module.scss'
import { Container, Row, Col } from '~views/shared/components'
import { IMapSection } from '~models'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import styles from './mapStyle.json'
import BackgroundImage from '~views/shared/components/BackgroundImage'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import { Icons } from '~libs/assets'
import { createMarkup } from '~libs/utils'

interface Props {
  section: IMapSection
}

const Map = withScriptjs(
  withGoogleMap((props: any) => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: props.latitude, lng: props.longitude }}
      defaultOptions={{
        disableDefaultUI: true,
        draggableCursor: 'default',
        keyboardShortcuts: false,
        scaleControl: true,
        styles,
      }}
    >
      <Marker
        position={{ lat: props.latitude, lng: props.longitude }}
        icon={{ url: Icons.map_pin, scaledSize: { width: 45, height: 55 } }}
      />
    </GoogleMap>
  )),
)

export class MapSection extends React.Component<Props> {
  renderSocialMedia = (item, key) => {
    const { icon, link } = item
    return (
      <DynamicLink to={link} key={`social_media_${key}`} className={s.socialMedia}>
        <BackgroundImage image={icon} className={s.socialMediaIcon} />
      </DynamicLink>
    )
  }

  render() {
    const { section } = this.props
    const { sectionTitle, sectionText, information, map } = section
    const { contactInformation, socialMedia } = information

    const apiKey =
      'https://maps.googleapis.com/maps/api/js?key= ' + process.env.GATSBY_GMAPS_KEY

    return (
      <section data-sectionname="Map">
        <Container noPadding fluid className={s.customContainer}>
          <Row noMargin className={s.sectionInformation}>
            <h1 className={s.sectionTitle}>{sectionTitle}</h1>
            <div className={s.sectionText}>
              <p className="ingress">{sectionText}</p>
            </div>
          </Row>
          <div className={s.columnWrapper}>
            <div className={s.informationWrapper}>
              <div
                className={s.contactInformation}
                dangerouslySetInnerHTML={createMarkup(contactInformation)}
              />
              <div className={s.socialMediaWrapper}>
                {socialMedia && socialMedia.map(this.renderSocialMedia)}
              </div>
            </div>
            <Map
              latitude={map.latitude}
              longitude={map.longitude}
              googleMapURL={apiKey}
              loadingElement={<div className="h-100" />}
              containerElement={<div className={s.mapContainer} />}
              mapElement={<div className="h-100" style={{ borderRadius: `15px` }} />}
            />
          </div>
        </Container>
      </section>
    )
  }
}
