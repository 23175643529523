import React from 'react'
import s from './TextAndImage.module.scss'
import { Container } from '~views/shared/components'
import { ITextAndImageSection } from '~models'
import { GatsbyImage } from 'gatsby-plugin-image'
import { createMarkup } from '~libs/utils'

interface Props {
  section: ITextAndImageSection
}
export class TextAndImageSection extends React.Component<Props> {
  renderText = (textObject, key) => {
    const { textGroup } = textObject
    const { type, text, wysiwyg } = textGroup

    let element = null

    if (type === 'heading') {
      element = <h2 key={key}>{text}</h2>
    } else if (type === 'ingress' || type === 'paragraph') {
      element = (
        <p className={type} key={key}>
          {text}
        </p>
      )
    } else if (type === 'wysiwyg') {
      element = (
        <div
          className={s[type]}
          key={key}
          dangerouslySetInnerHTML={createMarkup(wysiwyg)}
        />
      )
    }

    return element
  }

  render() {
    const { section } = this.props
    const { texts, image } = section

    return (
      <section data-sectionname="TextAndImage">
        <Container noPadding fluid className={s.customContainer}>
          <div className={s.textWrapper}>
            {texts && texts.map((textObject, key) => this.renderText(textObject, key))}
          </div>
          <div className={s.imageWrapper}>
            {image && (
              <GatsbyImage
                alt=""
                image={image.localFile?.childImageSharp.image}
                className={s.image}
              />
            )}
          </div>
        </Container>
      </section>
    )
  }
}
