import React, { Component } from 'react'
import { Icons } from '~libs/assets'
import { Helmet } from 'react-helmet'
import { ISeo } from '~models'
import { rewriteToInternal } from '~libs/utils'

interface Props {
  children?: any
}

export class Seo extends Component<ISeo> {
  render() {
    const { seo, slug } = this.props

    let title = ''
    if (seo.opengraphTitle) {
      title = seo.opengraphTitle
    } else if (slug) {
      title = `${slug.charAt(0).toUpperCase() + slug.slice(1)} | ${
        seo.title ? seo.title : ''
      }`
    } else {
      title = `${seo ? seo.title : ''} | ${seo ? seo.metaDesc : ''}`
    }

    const metaDescription = seo.metaDesc
    const canonicalUrl = seo && seo.opengraphUrl && rewriteToInternal(seo.opengraphUrl)

    const ogTitle = seo && seo.opengraphTitle
    const ogUrl = seo && seo.opengraphUrl && rewriteToInternal(seo.opengraphUrl)
    const ogDescription = seo && seo.opengraphDescription
    const ogImage =
      seo &&
      seo.opengraphImage &&
      seo.opengraphImage.sourceUrl &&
      seo.opengraphImage.sourceUrl.replace('https://', 'http://')
    const ogImageSecure = seo && seo.opengraphImage && seo.opengraphImage.sourceUrl

    const twitterTitle = seo && seo.twitterTitle
    const twitterDescription = seo && seo.twitterDescription
    const twitterImage = seo && seo.twitterImage && seo.twitterImage.sourceUrl

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <meta name="author" content={'Swace Digital'} />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta charSet="UTF-8" />
        <html lang="sv" />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="shortcut icon" type="image/x-icon" href={Icons.fav_icon} />
        <link rel="preload" href="./fonts/FaricyNew-Bold.woff" as="font" />

        <meta property="og:title" content={ogTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:secure_url" content={ogImageSecure} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:site_name" content={'Villa Baro'} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={twitterTitle} />
        <meta name="twitter:description" content={twitterDescription} />
        <meta name="twitter:image:src" content={twitterImage} />
        <meta
          name="facebook-domain-verification"
          content="fntcs2nd2runqtcu9schrcpy5w7321"
        />
      </Helmet>
    )
  }
}
