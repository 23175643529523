import React from 'react'
import s from './InformationImage.module.scss'
import { Container, ImageSlider } from '~views/shared/components'
import { IInformationImageSection } from '~models'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Icons } from '~libs/assets'

interface Props {
  section: IInformationImageSection
}
export class InformationImageSection extends React.Component<Props> {
  render() {
    const { section } = this.props
    const { imagePosition, sectionTitle, imageInformation, textInformation } = section
    const { image, imageCaption, imageOrSlider, sliderImages } = imageInformation
    const { title, text, showButton, bookingButton, button } = textInformation
    const { title: buttonTitle, link } = button

    let customContainerClasses = [s.customContainer]
    let textContainerClasses = [s.textContainer]
    let buttonClasses = [s.button]
    let buttonTitleClasses = [s.buttonTitle]

    switch (imagePosition) {
      case 'left':
        textContainerClasses.push(s.imageLeft)
        break
      case 'right':
        customContainerClasses.push(s.alignRight)
        textContainerClasses.push(s.imageRight)
        break
    }

    if (bookingButton) {
      buttonClasses.push(s.bookingButton)
      buttonTitleClasses.push(s.bookingButton)
    }

    return (
      <section data-sectionname="InformationImage">
        <Container noPadding className={customContainerClasses.join(' ')}>
          {sectionTitle && (
            <div className={s.sectionTitle}>
              <h2>{sectionTitle}</h2>
            </div>
          )}
          <div className={s.imageContainer}>
            {imageOrSlider === 'image' && image && (
              <>
                {image && image.localFile && (
                  <GatsbyImage
                    alt=""
                    className={s.image}
                    image={image.localFile.childImageSharp.image}
                  />
                )}
                {imageCaption && <div className={s.imageCaption}>{imageCaption}</div>}
              </>
            )}
            {imageOrSlider === 'slider' && sliderImages && sliderImages.length > 0 && (
              <ImageSlider slides={sliderImages} slideTime={8000} />
            )}
          </div>
          <div className={textContainerClasses.join(' ')}>
            {title && (
              <div className={s.heading}>
                <h2>{title}</h2>
              </div>
            )}
            {text && (
              <div className={s.text}>
                <p className={s.paragraph}>{text}</p>
              </div>
            )}
            {showButton && (
              <DynamicLink to={link?.link} className={buttonClasses.join(' ')}>
                <div className={buttonTitleClasses.join(' ')}>{buttonTitle}</div>
                {bookingButton && <img src={Icons.arrowRight} className={s.arrowRight} />}
              </DynamicLink>
            )}
          </div>
        </Container>
      </section>
    )
  }
}
