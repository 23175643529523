import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import s from './BackgroundImage.module.scss'
import { IImage } from '~models/Image'

export enum Vertical {
  top = '0%',
  center = '50%',
  bottom = '100%',
}

export enum Horizontal {
  left = '25%',
  center = '50%',
  right = '75%',
}

interface Props {
  image: IImage
  children?: any
  loading?: 'lazy' | 'eager'
  className?: string
  backgroundColor?: string
  alt?: string
  imagePositioning?: {
    vertical: Vertical
    horizontal: Horizontal
  }
  objectFit?: `fill` | `contain` | `cover` | `none` | `scale-down`
}

const BackgroundImage = ({
  image,
  children,
  loading,
  className,
  backgroundColor,
  alt,
  imagePositioning,
  objectFit,
}: Props) => {
  const wrapperClasses = [s.wrapper]
  if (className) {
    wrapperClasses.push(className)
  }

  const vert =
    (imagePositioning && Vertical[imagePositioning.vertical]) || Vertical.center
  const horiz =
    (imagePositioning && Horizontal[imagePositioning.horizontal]) || Horizontal.center

  return (
    <div className={wrapperClasses.join(' ')}>
      {image && image.localFile && (
        <GatsbyImage
          alt={alt}
          className={s.image}
          image={image.localFile.childImageSharp.image}
          loading={loading}
          objectFit={objectFit}
          backgroundColor={backgroundColor}
          objectPosition={`${horiz} ${vert}`}
        />
      )}
      {image && image.url && <img className={s.image} src={image.url} />}
      <div className={s.content}>{children}</div>
    </div>
  )
}

export default BackgroundImage
