import React from 'react'
import s from './Footer.module.scss'
import { inject, observer } from 'mobx-react'
import { Container } from '~views/shared/components'
import { graphql, StaticQuery } from 'gatsby'
import { IFooter } from '~models'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import { FooterLogo } from './FooterLogo'
import { FooterDecoration } from './FooterDecoration'
import { FooterSocialMedia } from './FooterSocialMedia'
import { WpmlStore } from '~stores'

interface Props {
  data: IFooter
  wpmlStore?: WpmlStore
}

@inject('wpmlStore')
@observer
export class FooterComponent extends React.Component<Props> {
  renderMenuItem = (item, key) => {
    const { itemTitle, itemInformation } = item
    const { linkType, internalLink, externalLink } = itemInformation
    return (
      <li className={s.menuItem} key={key}>
        <DynamicLink to={linkType === 'internal' ? internalLink?.link : externalLink}>
          {itemTitle}
        </DynamicLink>
      </li>
    )
  }

  render() {
    const { data, wpmlStore } = this.props
    const { lang } = wpmlStore
    const { themeSettingsLanguages } = data.wp.themeSettings.themeSettingsLanguages

    const { footerMenu, footerSocialMedia } =
      lang === 'en'
        ? themeSettingsLanguages.englishSettings.settingsFooter
        : themeSettingsLanguages.swedishSettings.settingsFooter

    const { contactAddress, contactEmail, contactPhoneNumber } =
      lang === 'en'
        ? themeSettingsLanguages.englishSettings.settingsContactInformation
        : themeSettingsLanguages.swedishSettings.settingsContactInformation

    return (
      <footer className={s.footerWrapper}>
        <Container noPadding className={s.customContainer}>
          <ul className={s.footerMenu}>
            {footerMenu && footerMenu.map(this.renderMenuItem)}
            {footerSocialMedia && <FooterSocialMedia props={footerSocialMedia} />}
          </ul>
          <div className={s.contactInformation}>
            {contactAddress && (
              <div className={s.contactInformationItem}>{contactAddress}</div>
            )}
            {contactEmail && (
              <div className={s.contactInformationItem}>
                <a href={`mailto: ${contactEmail}`}>{contactEmail}</a>
              </div>
            )}
            {contactPhoneNumber && (
              <div className={s.contactInformationItem}>
                <a href={`tel: ${contactPhoneNumber}`}>{contactPhoneNumber}</a>
              </div>
            )}
          </div>
          <FooterLogo />
        </Container>
        <FooterDecoration />
      </footer>
    )
  }
}

export const Footer = (props: IFooter) => (
  <StaticQuery
    query={query}
    render={(data) => <FooterComponent {...props} data={data} />}
  />
)

const query = graphql`
  query {
    wp {
      themeSettings {
        themeSettingsLanguages {
          themeSettingsLanguages {
            englishSettings {
              settingsFooter {
                footerMenu {
                  itemInformation {
                    externalLink
                    internalLink {
                      ... on WpPage {
                        link
                        title
                      }
                    }
                    linkType
                  }
                  itemTitle
                }
                footerSocialMedia {
                  icon {
                    localFile {
                      url
                      childImageSharp {
                        image: gatsbyImageData(
                          width: 64
                          quality: 100
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                  link
                }
              }
              settingsContactInformation {
                contactAddress
                contactEmail
                contactPhoneNumber
              }
            }
            swedishSettings {
              settingsFooter {
                footerMenu {
                  itemInformation {
                    externalLink
                    internalLink {
                      ... on WpPage {
                        link
                        title
                      }
                    }
                    linkType
                  }
                  itemTitle
                }
                footerSocialMedia {
                  icon {
                    localFile {
                      url
                      childImageSharp {
                        image: gatsbyImageData(
                          width: 64
                          quality: 100
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                        )
                      }
                    }
                  }
                  link
                }
              }
              settingsContactInformation {
                contactAddress
                contactEmail
                contactPhoneNumber
              }
            }
          }
        }
      }
    }
  }
`
