import React, { Component } from 'react'
import s from './LanguageSwitch.module.scss'
import { inject, observer } from 'mobx-react'
import { WpmlStore, NavigationStore, UIStore } from '~stores'
import { Icons } from '~libs/assets'

interface LanguageSwitchProps {
  wpmlStore?: WpmlStore
  navigationStore?: NavigationStore
  uiStore?: UIStore
}

@inject('wpmlStore', 'navigationStore', 'uiStore')
@observer
export class LanguageSwitch extends Component<LanguageSwitchProps> {
  /* 
    Change the to site in other language. It will redirect to the homepage.
    Can add support to stay on the same page but new language.
  */

  render() {
    const { wpmlStore, navigationStore, uiStore } = this.props
    let flag = Icons.flag_en
    const wrapperClasses = [s.wrapper]
    let display = uiStore.isAboveOrEqualToBreakpoint('md')

    if (wpmlStore.lang === 'en') flag = Icons.flag_sv

    if (navigationStore.menuOpen) {
      wrapperClasses.push(s.open)
      display = true
    }

    return (
      <>
        {display && (
          <div className={wrapperClasses.join(' ')}>
            <button className={s.button} onClick={wpmlStore.changeLang}>
              <img width={35} height={22} src={flag} className={s.flag} />
            </button>
          </div>
        )}
      </>
    )
  }
}
