import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { inject, observer } from 'mobx-react'
import { INewVisitorOverlay } from '~models'
import s from './NewVisitorOverlay.module.scss'
import { Container, Button } from '~views/shared/components'
import { disableBodyScrolling, enableBodyScrolling } from '~libs/utils'
import { WpmlStore } from '~stores'

interface NewVisitorOverlayProps {
  data?: INewVisitorOverlay
  wpmlStore?: WpmlStore
  host: string
}

@inject('wpmlStore')
@observer
export class NewVisitorOverlayComponent extends Component<NewVisitorOverlayProps> {
  state = {
    newUserSession: false,
    showOverlay: false,
  }

  componentDidMount() {
    const newUserSession = this.isNewSession('newUserSession')
    const { themeSettingsLanguages } =
      this.props.data.wp.themeSettings.themeSettingsLanguages
    const { lang } = this.props.wpmlStore
    const { newVisitorShow: overlay_show } =
      lang === 'en'
        ? themeSettingsLanguages.englishSettings.settingsNewVisitorOverlay
        : themeSettingsLanguages.swedishSettings.settingsNewVisitorOverlay

    disableBodyScrolling()
    if (!overlay_show) {
      enableBodyScrolling()
    } else {
      if (newUserSession) {
        this.setState({
          newUserSession: true,
          showOverlay: true,
        })
      } else {
        enableBodyScrolling()
        setTimeout(() => {
          this.setState({
            showOverlay: false,
          })
        }, 1000)
      }
    }
  }

  setSessionItem = (sessionItemName, value) => {
    sessionStorage.setItem(sessionItemName, value)
  }

  getSessionItem = (sessionItemName) => {
    return sessionStorage.getItem(sessionItemName)
  }

  isNewSession = (sessionItemName) => {
    var newUserSession = this.getSessionItem(sessionItemName)
    if (newUserSession === 'no') {
      return false
    } else {
      return true
    }
  }

  closeOverlay = () => {
    const sessionItemName = 'newUserSession'
    this.setSessionItem(sessionItemName, 'no')
    this.setState({ showOverlay: false })
    enableBodyScrolling()
  }

  render() {
    const { newUserSession, showOverlay } = this.state
    const { wpmlStore, data } = this.props
    const { lang } = wpmlStore
    const { themeSettingsLanguages } = data.wp.themeSettings.themeSettingsLanguages
    const {
      newVisitorShow: toggle_show,
      newVisitorTitle: title,
      newVisitorText: text,
      newVisitorButtonText: buttonText,
    } = lang === 'en'
      ? themeSettingsLanguages.englishSettings.settingsNewVisitorOverlay
      : themeSettingsLanguages.swedishSettings.settingsNewVisitorOverlay
    const containerClasses = [s.container]

    if (showOverlay && toggle_show) {
      containerClasses.push(s.show)
    }

    if (!newUserSession) {
      return null
    } else {
      return (
        <Container className={containerClasses.join(' ')} fluid>
          <div className={`${s.contentWrapper} col-10 col-md-4 align-content-center`}>
            <h1 className={s.title}>{title}</h1>
            <p className={s.text}>{text}</p>
            <Button className={s.button} onClick={this.closeOverlay}>
              {buttonText}
            </Button>
          </div>
          <button className={s.close} onClick={() => this.closeOverlay()} />
        </Container>
      )
    }
  }
}

export const NewVisitorOverlay = (props: NewVisitorOverlayProps) => (
  <StaticQuery
    query={query}
    render={(data) => <NewVisitorOverlayComponent {...props} data={data} />}
  />
)

const query = graphql`
  query {
    wp {
      themeSettings {
        themeSettingsLanguages {
          themeSettingsLanguages {
            englishSettings {
              settingsHeader {
                headerCta {
                  headerCtaTitle
                  headerCtaLink
                  headerCtaLinkTwo
                }
              }
              settingsNewVisitorOverlay {
                newVisitorShow
                newVisitorButtonText
                newVisitorText
                newVisitorTitle
              }
            }
            swedishSettings {
              settingsHeader {
                headerCta {
                  headerCtaTitle
                  headerCtaLink
                  headerCtaLinkTwo
                }
              }
              settingsNewVisitorOverlay {
                newVisitorShow
                newVisitorButtonText
                newVisitorText
                newVisitorTitle
              }
            }
          }
        }
      }
    }
  }
`
