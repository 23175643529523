import React from 'react'
import s from './ContactForm.module.scss'
import { inject, observer } from 'mobx-react'
import { ContactFormStore, WpmlStore } from '~stores'
import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import 'react-datepicker/dist/react-datepicker.css'

interface Props {
  contactFormStore?: ContactFormStore
  wpmlStore?: WpmlStore
  checkIn?: boolean
  checkOut?: boolean
}

@inject('wpmlStore', 'contactFormStore')
@observer
export class ContactFormDatePicker extends React.Component<Props> {
  private checkInPickerRef
  private checkOutPickerRef

  constructor(props) {
    super(props)
    this.checkInPickerRef = React.createRef()
    this.checkOutPickerRef = React.createRef()
    this.props.contactFormStore.setMinCheckOutDate(this.props.contactFormStore.dateToday)
    this.props.contactFormStore.setMaxCheckInDate(this.props.contactFormStore.dateToday)

    this.props.contactFormStore.arrivalDate = this.props.contactFormStore.formatDateToSlimFormat(
      this.props.contactFormStore.dateToday,
    )

    this.props.contactFormStore.departureDate = this.props.contactFormStore.formatDateToSlimFormat(
      this.props.contactFormStore.minCheckOutDate,
    )
    registerLocale('sv', sv)
  }

  componentDidMount() {
    if (this.checkInPickerRef.current) {
      this.checkInPickerRef.current.input.readOnly = true
    }
    if (this.checkOutPickerRef.current) {
      this.checkOutPickerRef.current.input.readOnly = true
    }
  }

  setDate = (e, type) => {
    const { formatDateToSlimFormat } = this.props.contactFormStore
    this.props.contactFormStore.activities = []

    if (type === 'checkIn') {
      this.props.contactFormStore.checkInDate = e
      this.props.contactFormStore.arrivalDate = formatDateToSlimFormat(e)
      this.props.contactFormStore.setMinCheckOutDate(e)

      if (
        this.props.contactFormStore.departureDate <
        this.props.contactFormStore.arrivalDate
      ) {
        this.props.contactFormStore.departureDate = this.props.contactFormStore.formatDateToSlimFormat(
          this.props.contactFormStore.minCheckOutDate,
        )
      }
    } else if (type === 'checkOut') {
      this.props.contactFormStore.checkOutDate = e
      this.props.contactFormStore.departureDate = formatDateToSlimFormat(e)
      this.props.contactFormStore.setMaxCheckInDate(e)
    }
  }

  handleDateChangeRaw = e => {
    e.preventDefault()
  }

  render() {
    const { contactFormStore, wpmlStore, checkIn, checkOut } = this.props
    const { lang } = wpmlStore
    const {
      dateToday,
      checkInDate,
      checkOutDate,
      minCheckOutDate,
      maxCheckInDate,
      toggleCheckIn,
      toggleCheckOut,
      checkInOpen,
      checkOutOpen,
      //initiateScroll,
    } = contactFormStore

    const dropdownClasses = [s.dropdown, s.datepicker, s.contactForm]
    const datepickerContainerClasses = [s.datepickerContainer]
    const datepickerWrapperClasses = [s.datepickerWrapper]
    const checkInWrapperClasses = []
    const checkOutWrapperClasses = [s.checkOutPicker]

    const isEn = lang === 'en'
    const checkInTitle = isEn ? 'Check in' : 'Ankomsttid'
    const checkOutTitle = isEn ? 'Check out' : 'Avresetid'

    return (
      <div className={dropdownClasses.join(' ')}>
        <div className={datepickerContainerClasses.join(' ')}>
          {!!checkIn && (
            <div
              className={
                datepickerWrapperClasses.join(' ') + ' ' + checkInWrapperClasses.join(' ')
              }
            >
              <div className={s.datePickerTitleContactForm}>{checkInTitle}</div>
              <DatePicker
                ref={this.checkInPickerRef}
                dateFormat={'E d MMM'}
                popperClassName={s.checkInPopper}
                showPopperArrow={false}
                monthsShown={1}
                locale={isEn ? 'en' : 'sv'}
                selected={checkInDate}
                onSelect={date => {
                  this.setDate(date, 'checkIn')
                  toggleCheckIn()
                }}
                onFocus={e => {
                  e.preventDefault()
                  !checkInOpen && toggleCheckIn()
                  //initiateScroll()
                }}
                onChangeRaw={this.handleDateChangeRaw}
                selectsStart
                startDate={checkInDate}
                endDate={checkOutDate}
                minDate={dateToday}
                maxDate={maxCheckInDate ? maxCheckInDate : null}
                required
                fixedHeight
                mouse
                onClickOutside={checkInOpen && toggleCheckIn}
              />
            </div>
          )}
          {!!checkOut && (
            <div
              className={
                datepickerWrapperClasses.join(' ') +
                ' ' +
                checkOutWrapperClasses.join(' ')
              }
            >
              <div className={s.datePickerTitleContactForm}>{checkOutTitle}</div>
              <DatePicker
                ref={this.checkOutPickerRef}
                dateFormat={'E d MMM'}
                popperClassName={s.checkOutPopper}
                showPopperArrow={false}
                monthsShown={1}
                locale={isEn ? 'en' : 'sv'}
                selected={checkOutDate ? checkOutDate : minCheckOutDate}
                onSelect={date => {
                  this.setDate(date, 'checkOut')
                  toggleCheckOut()
                }}
                onFocus={e => {
                  e.preventDefault()
                  !checkOutOpen && toggleCheckOut()
                  //initiateScroll()
                }}
                onChangeRaw={this.handleDateChangeRaw}
                selectsEnd
                openToDate={minCheckOutDate}
                startDate={checkInDate}
                endDate={checkOutDate}
                minDate={minCheckOutDate}
                required
                fixedHeight
                onClickOutside={checkOutOpen && toggleCheckOut}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
