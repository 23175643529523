import React from 'react'
import s from './404.module.scss'
import { I404Sv } from '~models'
import { Col, Row, Container } from '~views/shared/components'
import { StaticQuery, graphql } from 'gatsby'
import BackgroundImage from '~views/shared/components/BackgroundImage'

interface Props {
  data: I404Sv
}

class NotFoundPageComponent extends React.Component<Props> {
  render() {
    const { data } = this.props
    const { title } = data.wp.generalSettings
    const { settings404 } =
      data.wp.themeSettings.themeSettingsLanguages.themeSettingsLanguages.swedishSettings
    const { wordpress404Title, wordpress404Text, wordpress404Logo } = settings404

    return (
      <section data-sectionname="404">
        <Container>
          <Row className={s.customRow}>
            <Col xs="12">
              <a href="/" className={s.logo}>
                <BackgroundImage
                  image={wordpress404Logo}
                  className={s.image}
                  objectFit="scale-down"
                />
              </a>
            </Col>
            <Col xs="12">
              <h1>404</h1>
            </Col>
            <Col xs="12" sm="8" className={s.centered}>
              <div className={s.textWrapper}>
                <h3 className={s.title}>{wordpress404Title || 'Missing Title'}</h3>
                {wordpress404Text && <div className={s.text}>{wordpress404Text}</div>}
              </div>

              <a href="/" className={s.link}>
                {'Tillbaka till '}
                <div className={s.siteName}>{title}</div>
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export const NotFoundPage = (props: I404Sv) => (
  <StaticQuery
    query={query}
    render={(data) => <NotFoundPageComponent {...props} data={data} />}
  />
)

const query = graphql`
  query {
    wp {
      themeSettings {
        themeSettingsLanguages {
          themeSettingsLanguages {
            swedishSettings {
              settings404 {
                wordpress404Title
                wordpress404Text
                wordpress404Logo {
                  localFile {
                    url
                    childImageSharp {
                      image: gatsbyImageData(
                        width: 132
                        quality: 50
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
      generalSettings {
        title
      }
    }
  }
`

export default NotFoundPage
