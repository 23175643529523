import React from 'react'
import { IActivityGridSection } from '~models'
import s from './ActivityGrid.module.scss'
import { Container } from '~views/shared/components'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import BackgroundImage from '~views/shared/components/BackgroundImage'

interface Props {
  section: IActivityGridSection
}

export class ActivityGridSection extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.state = {
      width: typeof window !== 'undefined' ? window.innerWidth : 0,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    this.setState({ width: typeof window !== 'undefined' ? window.innerWidth : 0 })
  }

  renderCard = (item, key) => {
    const { title, link, image } = item
    return (
      <div className={s.card} key={key}>
        <DynamicLink to={link?.link}>
          <BackgroundImage image={image} className={s.image} />
        </DynamicLink>
        <h3 className={s.cardTitle}>{title}</h3>
      </div>
    )
  }

  render() {
    const { section } = this.props
    const { sectionTitle, cards } = section
    return (
      <section data-sectionname="ActivityGrid">
        <Container noPadding fluid className={s.container}>
          {sectionTitle && <h2 className={s.sectionTitle}>{sectionTitle}</h2>}
          {this.state.width && this.state.width > 1000 && (
            <>
              <div className={s.cardsContainer1}>
                {cards && cards.slice(0, 3).map(this.renderCard)}
              </div>
              <div className={s.cardsContainer2}>
                {cards && cards.slice(3, 7).map(this.renderCard)}
              </div>
            </>
          )}
          {this.state.width && this.state.width <= 1000 && (
            <>
              <div className={s.cardsContainer2}>
                {cards && cards.map(this.renderCard)}
              </div>
            </>
          )}
        </Container>
      </section>
    )
  }
}
