import { withPrefix } from 'gatsby'

export const Icons: { [key: string]: string } = {
  error: withPrefix('/icons/error.svg'),
  close: withPrefix('/icons/close.svg'),
  arrow_down: withPrefix('/icons/arrow_down.svg'),
  arrow_right: withPrefix('/icons/arrow_right.svg'),
  triangle_down: withPrefix('/icons/triangle_down.svg'),
  triangle_right: withPrefix('/icons/triangle_right.svg'),
  white_arrow_down: withPrefix('/icons/white_arrow_down.svg'),
  twitter: withPrefix('/icons/twitter.svg'),
  facebook: withPrefix('/icons/facebook.svg'),
  instagram: withPrefix('/icons/instagram.svg'),
  bank_id: withPrefix('/icons/bank_id.png'),
  check_mark: withPrefix('/icons/check_mark.svg'),
  check_circle: withPrefix('/icons/check-circle.svg'),
  lock: withPrefix('/icons/lock.svg'),
  video_library: withPrefix('/icons/video-library.svg'),
  hemma_logo: withPrefix('icons/hemma.svg'),
  fav_icon: withPrefix('/icons/favicon.png'),
  map_pin: withPrefix('/icons/map_pin.svg'),
  green_wave_webp: withPrefix('/icons/green_wave.webp'),
  green_wave_png: withPrefix('/icons/green_wave.png'),
  blue_wave_webp: withPrefix('/icons/blue_wave.webp'),
  blue_wave_png: withPrefix('/icons/blue_wave.png'),
  swan_webp: withPrefix('/icons/swan.webp'),
  swan_png: withPrefix('/icons/swan.png'),
  hill_tree_webp: withPrefix('/icons/hill_tree.webp'),
  hill_tree_jpg: withPrefix('/icons/hill_tree.jpg'),
  flag_en: withPrefix('/icons/flag_en.svg'),
  flag_sv: withPrefix('/icons/flag_sv.svg'),
  simple_arrow_right: withPrefix('/icons/simple_arrow_right.svg'),
  simple_arrow_left: withPrefix('/icons/simple_arrow_left.svg'),
}
