import * as React from 'react'
import s from './ActivityCalendar.module.scss'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import BackgroundImage from '~views/shared/components/BackgroundImage'
import { ISingleWordpressActivityPage } from '~models'

interface Props {
  node: ISingleWordpressActivityPage
  lang: string
}

const formatDate = (date, lang) => {
  const dateOptions = {
    month: 'short',
    day: 'numeric',
  }
  let formatting = 'sv-SV'
  if (lang === 'en') formatting = 'en-EN'

  const UTCDate = new Date(date)
  const formattedDate = UTCDate.toLocaleDateString(formatting, dateOptions).replace(
    '.',
    '',
  )

  return formattedDate
}

const Activity = (props: Props) => {
  return (
    <DynamicLink
      to={props.node.link}
      className={s.activityPreviewWrapper}
      key={props.node.id}
    >
      <div className={s.previewImageWrapper}>
        <div className={s.activityDate}>
          {formatDate(props.node.page.activityStartDate, props.lang)}
          {props.node.page.activityEndDate
            ? ' - ' + formatDate(props.node.page.activityEndDate, props.lang)
            : ''}
        </div>
        <BackgroundImage
          image={props.node.page.activityPreviewImage}
          className={s.previewImage}
        />
      </div>
      <div className={s.activityTitle}>{props.node.page.activityPreviewTitle}</div>
      <div className={s.activityText}>{props.node.page.activityPreviewText}</div>
    </DynamicLink>
  )
}

export default Activity
