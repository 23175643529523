import React from 'react'
import s from './Header.module.scss'
import { LanguageSwitch } from '~views/shared/components'
import { graphql, StaticQuery } from 'gatsby'
import { inject, observer } from 'mobx-react'
import { HeaderLogo, HeaderCTA, HeaderHamburger } from '.'
import { NavigationStore, WpmlStore } from '~stores'
import { IHeaderNavMenu } from '~models'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import { Icons } from '~libs/assets'

interface NavMenuProps {
  data: IHeaderNavMenu
  navigationStore?: NavigationStore
  wpmlStore?: WpmlStore
}

@inject('navigationStore', 'wpmlStore')
@observer
export class HeaderNavMenuComponent extends React.Component<NavMenuProps> {
  renderNavmenuItem = (position: string, item, key) => {
    const { submenuOpen, activeSubmenuIndex, toggleSubmenu } = this.props.navigationStore
    const { title, link, hasSubmenu } = item
    let menuItemClasses = [s.menuItem]
    let menuItemTitleWrapperClasses = [s.menuItemTitleWrapper]
    const submenu = hasSubmenu === 'yes'
    const currentKey = position === 'left' ? key : key + 3

    if (submenu) {
      const isOpen = submenuOpen && activeSubmenuIndex === currentKey
      menuItemClasses.push(s.hasSubmenu)
      menuItemTitleWrapperClasses.push(s.hasSubmenu)
      isOpen && menuItemTitleWrapperClasses.push(s.open)
    }

    return (
      <div className={menuItemClasses.join(' ')} key={currentKey}>
        {!submenu && (
          <DynamicLink
            style={{ 'text-decoration': 'none' }}
            to={link?.link}
            onClick={this.props.navigationStore.closeMenu}
          >
            <h2 className={s.menuItemTitle}>{title}</h2>
          </DynamicLink>
        )}
        {submenu && (
          <div
            className={menuItemTitleWrapperClasses.join(' ')}
            onClick={() => toggleSubmenu(currentKey)}
          >
            <h2 className={s.menuItemTitle}>{title}</h2>
            <img src={Icons.triangle_down} className={s.menuItemArrow} />
          </div>
        )}
      </div>
    )
  }

  renderSubmenu = (item, key) => {
    return (
      <div className={s.item} key={key}>
        <DynamicLink
          to={item.link?.link}
          onClick={() => {
            this.props.navigationStore.toggleMenu()
          }}
        >
          <h2>{item.title}</h2>
        </DynamicLink>
      </div>
    )
  }
  renderMenu = (item, key) => {
    const { title, link } = item
    return (
      <div className={s.item} key={key}>
        <DynamicLink to={link?.link} onClick={this.props.navigationStore.toggleMenu}>
          <h2>{title}</h2>
        </DynamicLink>
      </div>
    )
  }

  closeMenus = () => {
    this.props.navigationStore.menuOpen = false
    this.props.navigationStore.submenuOpen = false
    this.props.navigationStore.activeSubmenuIndex = -1
    this.props.navigationStore.openMenuItems = []
  }

  render() {
    const { navigationStore } = this.props
    const { menuOpen, submenuOpen } = navigationStore
    const { data, wpmlStore } = this.props
    const { lang } = wpmlStore
    const { navigationLanguages } = data.wp.navigation.navigationLanguages
    const { mainMenu, ctaTitle, ctaLink, displayCta } =
      lang === 'en'
        ? navigationLanguages.englishSettings
        : navigationLanguages.swedishSettings

    return (
      <div>
        <section className={menuOpen || submenuOpen ? s.navMenuOpen : s.navMenu}>
          <div className={s.menuBarWrapper}>
            <HeaderHamburger />
            {mainMenu && (
              <div className={s.navmenuItemsLeft}>
                {mainMenu
                  .slice(0, 3)
                  .map((key, index) => this.renderNavmenuItem('left', key, index))}
              </div>
            )}
            <HeaderLogo />
            {mainMenu && (
              <div className={s.navmenuItemsRight}>
                {mainMenu
                  .slice(3, 6)
                  .map((key, index) => this.renderNavmenuItem('right', key, index))}
              </div>
            )}
          </div>
          {menuOpen && (
            <div className={s.menuItems}>
              {mainMenu.slice(6, mainMenu.length).map(this.renderMenu)}
              {displayCta && (
                <DynamicLink to={ctaLink} onClick={navigationStore.closeMenu}>
                  <h2 className={s.CTAtitle}>{ctaTitle}</h2>
                </DynamicLink>
              )}
            </div>
          )}
          <div>
            {submenuOpen && (
              <div className={s.submenuItems} onClick={navigationStore.closeMenu}>
                {mainMenu[navigationStore.activeSubmenuIndex].submenu.map(
                  this.renderSubmenu,
                )}
              </div>
            )}
          </div>
          <HeaderCTA />
          <LanguageSwitch />
        </section>
        {menuOpen || submenuOpen ? (
          <div className={s.overlay} onClick={this.closeMenus} />
        ) : null}
      </div>
    )
  }
}

export const HeaderNavMenu = (props: IHeaderNavMenu) => (
  <StaticQuery
    query={query}
    render={data => <HeaderNavMenuComponent {...props} data={data} />}
  />
)

const query = graphql`
  query {
    wp {
      navigation {
        navigationLanguages {
          navigationLanguages {
            englishSettings {
              displayCta: displayCta
              ctaTitle: ctaTitle
              ctaLink: ctaLink
              mainMenu {
                title
                link {
                  ... on WpPage {
                    link
                    title
                  }
                }
                hasSubmenu
                submenu {
                  link {
                    ... on WpPage {
                      link
                      title
                    }
                  }
                  title
                }
              }
            }
            swedishSettings {
              displayCta: displayCta
              ctaTitle: ctaTitle
              ctaLink: ctaLink
              mainMenu {
                title
                link {
                  ... on WpPage {
                    link
                    title
                  }
                }
                hasSubmenu
                submenu {
                  link {
                    ... on WpPage {
                      link
                      title
                    }
                  }
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`
