export type TScreenKeys = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

export const screenBreakpoints: { [key in TScreenKeys]: number } = {
  xs: 0,
  sm: 520,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1500,
}
