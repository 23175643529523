import React from 'react'
import { IActivitiesSection } from '~models'
import s from './Activities.module.scss'
import BackgroundImage from '~views/shared/components/BackgroundImage'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import { Container } from '~views/shared/components'

export interface ActivitiesSectionProps {
  section: IActivitiesSection
}

export class ActivitiesSection extends React.Component<ActivitiesSectionProps> {
  renderActivity = (activity, key) => {
    const { image, title, link } = activity
    return (
      <div className={s.activity} key={key}>
        <DynamicLink to={link}>
          <BackgroundImage image={image} className={s.image} />
        </DynamicLink>
        <div className={s.activityTitle}>
          <h3>{title}</h3>
        </div>
      </div>
    )
  }

  render() {
    const { section } = this.props
    const { activities, sectionTitle, sectionText } = section
    return (
      <section data-sectionname="Activities">
        <Container noPadding fluid>
          <div className={s.wrapper}>
            <h2 className={s.sectionTitle}>{sectionTitle}</h2>
            <div className={s.sectionText}>
              <p className="ingress">{sectionText}</p>
            </div>
            <div className={s.activitiesWrapper}>
              {activities && activities.map(this.renderActivity)}
            </div>
          </div>
        </Container>
      </section>
    )
  }
}
