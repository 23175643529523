import React from 'react'
import s from './Header.module.scss'
import { Container, LanguageSwitch } from '~views/shared/components'
import { HeaderLogo, HeaderCTA, HeaderHamburger, HeaderNavMenu } from '.'
import { HeaderLinksOverlay } from './HeaderLinksOverlay'
import { inject, observer } from 'mobx-react'
import { NavigationStore } from '~stores'

interface Props {
  navigationStore?: NavigationStore
}

@inject('navigationStore')
@observer
export class Header extends React.Component<Props> {
  render() {
    const { navigationStore } = this.props
    const { menuOpen } = navigationStore
    const wrapperClasses = [s.headerWrapper]

    return (
      <header className={wrapperClasses.join(' ')}>
        <Container fluid noPadding className={s.customContainer}>
          <div className={s.desktopView}>
            <HeaderNavMenu />
          </div>
          <div className={s.mobileView}>
            <div className={s.navMenuMobile} />
            <HeaderHamburger />
            <HeaderLogo />
            <HeaderCTA />
            <LanguageSwitch />
            {menuOpen && <HeaderLinksOverlay />}
            {menuOpen && <div className={s.topBar} />}
          </div>
        </Container>
      </header>
    )
  }
}
