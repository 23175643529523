import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { StaticQuery } from 'gatsby'
import { inject, observer } from 'mobx-react'
import { ICookie } from '~models'
import s from './Cookie.module.scss'
import { createMarkup } from '~libs/utils'
import { WpmlStore } from '~stores'

interface CookieProps {
  data?: ICookie
  wpmlStore?: WpmlStore
  host: string
}

@inject('wpmlStore')
@observer
class CookieComponent extends Component<CookieProps> {
  state = {
    cookiesAccepted: false,
    render: true,
  }

  componentDidMount() {
    const { host } = this.props
    const cookie = this.isCookieSet('cookieAccepted(' + host + ')')
    if (cookie) {
      this.setState({
        cookiesAccepted: true,
        render: false,
      })
    }
  }

  setCookie = (cname, cvalue, exdays) => {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  }

  getCookie = cname => {
    var name = cname + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  isCookieSet = cookieName => {
    var cookie = this.getCookie(cookieName)
    if (cookie === '') {
      return false
    } else {
      return true
    }
  }

  closeCookie = () => {
    const { host } = this.props
    const cookieName = 'cookieAccepted(' + host + ')'
    this.setCookie(cookieName, true, 10000)
    this.setState({ cookiesAccepted: true })
    setTimeout(() => this.setState({ render: false }), 1000)
  }

  render() {
    const { cookiesAccepted, render } = this.state
    const {
      themeSettingsLanguages,
    } = this.props.data.wp.themeSettings.themeSettingsLanguages
    const {
      cookieInformation,
      buttonLabel,
    } = themeSettingsLanguages.englishSettings.settingsCookie

    const cookieClasses = [s.cookie, 'col-10 col-md-8']
    if (cookiesAccepted) cookieClasses.push(s.hide)

    if (!render) {
      return null
    } else {
      return (
        <div className={cookieClasses.join(' ')}>
          <div className={'row'}>
            <div
              className={'col-8'}
              dangerouslySetInnerHTML={createMarkup(cookieInformation)}
            />
            <div className={`col-4 my-auto`}>
              <div className={`${s.button}`} onClick={() => this.closeCookie()}>
                {buttonLabel}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export const Cookie = (props: CookieProps) => (
  <StaticQuery
    query={query}
    render={data => <CookieComponent {...props} data={data} />}
  />
)

export const query = graphql`
  query {
    wp {
      themeSettings {
        themeSettingsLanguages {
          themeSettingsLanguages {
            englishSettings {
              settingsCookie {
                cookieInformation
                buttonLabel
              }
            }
            swedishSettings {
              settingsCookie {
                cookieInformation
                buttonLabel
              }
            }
          }
        }
      }
    }
  }
`

// TODO:
// wordpressSiteMetadata {
//   url
// }

export default Cookie
