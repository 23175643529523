import React from 'react'
import s from './Footer.module.scss'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import BackgroundImage from '~views/shared/components/BackgroundImage'

export const FooterSocialMedia = socialMedia => {
  return (
    <ul className={s.socialMediaList}>
      {socialMedia &&
        socialMedia.props.map((socialMediaItem, key) => {
          return (
            <li className={s.socialMediaEntry} key={`footer_social_media_${key}`}>
              <DynamicLink to={socialMediaItem.link} className={s.socialMediaLink}>
                <BackgroundImage
                  image={socialMediaItem.icon}
                  className={s.socialMediaIcon}
                />
              </DynamicLink>
            </li>
          )
        })}
    </ul>
  )
}
