import React, { createRef } from 'react'
import s from './ImageCardsGrid.module.scss'
import { Container } from '~views/shared/components'
import { IImageCardsGridSection } from '~models'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Icons } from '~libs/assets'
import { UIStore } from '~stores'
import { inject, observer } from 'mobx-react'

interface Props {
  section: IImageCardsGridSection
  uiStore?: UIStore
}

interface ImageCardGridState {
  cardsWrapperHeight: number
}

@inject('uiStore')
@observer
export class ImageCardsGridSection extends React.Component<Props, ImageCardGridState> {
  state = {
    cardsWrapperHeight: 0,
  }

  private cardsWrapperRef = createRef<HTMLDivElement>()

  componentDidMount = () => {
    this.calculateCardsWrapperHeight()
  }

  componentDidUpdate = () => {
    this.calculateCardsWrapperHeight()
  }

  calculateCardsWrapperHeight = () => {
    const desktop = typeof window !== 'undefined' && window.innerWidth >= 768

    let cardsWrapperHeight
    if (this.cardsWrapperRef && this.cardsWrapperRef.current) {
      const cardsWrapper = this.cardsWrapperRef.current
      const cardsWrapperCurrentHeight = cardsWrapper.clientHeight
      const cardsWrapperRect = cardsWrapper.getBoundingClientRect()
      const cardsWrapperTop = cardsWrapperRect.top

      const lastChild = cardsWrapper.lastElementChild
      const lastChildRect = lastChild.getBoundingClientRect()
      const lastChildBottom = lastChildRect.bottom

      const calculatedCardsWrapperHeight = lastChildBottom - cardsWrapperTop
      cardsWrapperHeight = Math.round(calculatedCardsWrapperHeight)

      if (desktop) {
        if (this.state.cardsWrapperHeight < cardsWrapperHeight) {
          this.setState({
            cardsWrapperHeight: cardsWrapperHeight + 1,
          })
        }
      } else {
        if (this.state.cardsWrapperHeight !== cardsWrapperHeight) {
          this.setState({
            cardsWrapperHeight: cardsWrapperHeight,
          })
        }
      }
    }
  }

  renderCard = (card, key, setOrder) => {
    const { image, title, text, linkInformation, category } = card
    const { linkType, externalLink, internalLink } = linkInformation

    let order = 1
    if (setOrder) {
      order = (key % 2) + 1
    }

    let link

    switch (linkType) {
      case 'internal':
        link = internalLink
        break
      case 'external':
        link = externalLink
        break
    }

    return (
      <div className={s.card} key={key} style={{ order: order }}>
        <DynamicLink to={link} className={s.imageWrapper}>
          {image && image.localFile && (
            <GatsbyImage
              alt=""
              className={s.image}
              image={image.localFile.childImageSharp.image}
            />
          )}
        </DynamicLink>
        <DynamicLink to={link} className={s.textWrapper}>
          <div className={s.category}>{category}</div>
          <div className={s.title}>{title}</div>
          <div className={s.text}>{text}</div>
        </DynamicLink>
      </div>
    )
  }

  render() {
    const { section, uiStore } = this.props
    const { cardsWrapperHeight } = this.state
    const { sectionTitle, cards } = section
    const { breakpoint } = uiStore

    const desktop = uiStore.isAboveOrEqualToBreakpoint('lg')
    const tablet = breakpoint === 'md'
    let setOrder: boolean = false

    let cardsWrapperMaxHeight

    if (tablet) {
      cardsWrapperMaxHeight = Math.ceil(cards.length / 2) * 492
      setOrder = true
    } else if (desktop) {
      cardsWrapperMaxHeight = Math.ceil(cards.length / 2) * 760
      setOrder = true
    } else {
      cardsWrapperMaxHeight = '100%'
    }

    let cardWrapperStyle = { maxHeight: cardsWrapperMaxHeight }

    return (
      <section data-sectionname="ImageCardsGrid">
        <Container className={s.customContainer}>
          <div className={s.sectionTitle}>
            <h2>{sectionTitle}</h2>
          </div>
          <div
            className={s.cardsWrapper}
            style={cardWrapperStyle}
            ref={this.cardsWrapperRef}
          >
            {cards && cards.map((item, key) => this.renderCard(item, key, setOrder))}
          </div>
        </Container>
        <div className={s.treeIcon}>
          <img src={Icons.hillTreeJpg} alt="hill with tree" />
        </div>
      </section>
    )
  }
}
