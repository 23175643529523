import { observable, action } from 'mobx'

export class WpmlStore {
  @observable
  lang: 'sv' | 'en' = 'sv'

  @action
  changeLang = () => {
    let prefix = ''
    if (this.lang === 'en') {
      this.lang = 'sv'
    } else {
      this.lang = 'en'
      prefix = 'en'
    }
    if (typeof window !== 'undefined') {
      window.location.href = `${window.location.origin}/${prefix}`
    }
  }

  onMount = () => {}

  onUnmount = () => {}
}
