import * as React from 'react'
import s from './ActivityCalendar.module.scss'
import { Container } from '~views/shared/components'
import Activity from './Activity'

interface Props {
  year: string
  activities: any
  lang: string
}

const renderActivityPreview = (node, key, lang) => {
  return <Activity key={key} node={node} lang={lang} />
}

const ActivitiesContainer = (props: Props) => (
  <Container
    noPadding
    fluid
    className={s.activitiesContainer}
    key={'activitiesWrapper_' + props.year}
  >
    <div className={s.activitiesPreviewWrapper}>
      {props.activities &&
        props.activities.map((activity, i) =>
          renderActivityPreview(activity, i, props.lang),
        )}
    </div>
  </Container>
)

export default ActivitiesContainer
