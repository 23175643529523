import React, { useEffect, useState, useContext, useRef } from 'react'
import s from '../WidgetCTA.module.scss'
import { observer, MobXProviderContext } from 'mobx-react'
import { graphql, useStaticQuery } from 'gatsby'
import { IWidgetCTA } from '~models'

const WidgetCTA = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const widgetContainerRef = useRef(null)

  const store = useContext(MobXProviderContext)

  const language = store.wpmlStore.lang

  const data: IWidgetCTA = useStaticQuery(query)
  const themeSettingsLanguages =
    data.wp.themeSettings.themeSettingsLanguages.themeSettingsLanguages

  useEffect(() => {
    const config = {
      channelId: 'c856861a-d0b9-44b9-8e01-021e0dc29af9',
      widgetLayout: 'StandardWide',
      WidgetContainerId: 'widgetContainer',
      promoCode: 'false',
      corpCode: 'false',
      agentCode: 'false',
      openOption: 'newwindow',
      loginLink: 'false',
      currency: 'SEK',
      languageCulture: language == 'en' ? 'en-GB' : 'sv-SE',
      accommodationTypePicker: 'false',
      allPackages: 'true',
      type: 'single_property',
    }

    const widgetScript = document.createElement('script')
    widgetScript.type = 'text/javascript'
    widgetScript.async = true
    widgetScript.src =
      'https://online.bookvisit.com/Content/widget/accommodation_widget.bundle.js'

    // @ts-ignore
    widgetScript.onload = () => {
      if (BV) {
        BV.SPWidget(config)
        setScriptLoaded(true)
      }
    }

    document.head.appendChild(widgetScript)

    const observer = new MutationObserver(() => {
      const subElement = widgetContainerRef.current?.querySelector(
        '[data-testid="search_button"]',
      )
      if (subElement) {
        subElement.innerHTML =
          language == 'en'
            ? themeSettingsLanguages.englishSettings.settingsBookingMenu.buttonText
            : themeSettingsLanguages.swedishSettings.settingsBookingMenu.buttonText
        observer.disconnect()
      }
    })

    observer.observe(widgetContainerRef.current, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
    }
  }, [language])

  return (
    <div
      className={`${s.widgetContainer} ${scriptLoaded ? s.loaded : ''}`}
      id="widgetContainer"
      ref={widgetContainerRef}
    ></div>
  )
}

export default observer(WidgetCTA)

const query = graphql`
  query {
    wp {
      themeSettings {
        themeSettingsLanguages {
          themeSettingsLanguages {
            englishSettings {
              settingsBookingMenu {
                buttonText
              }
            }
            swedishSettings {
              settingsBookingMenu {
                buttonText
              }
            }
          }
        }
      }
    }
  }
`
