import React, { Component } from 'react'
import s from './Header.module.scss'
import { graphql, StaticQuery } from 'gatsby'
import { inject, observer } from 'mobx-react'
import { NavigationStore, WpmlStore } from '~stores'
import { IHeaderHamburger } from '~models'

interface HamburgerProps {
  data: IHeaderHamburger
  navigationStore?: NavigationStore
  wpmlStore?: WpmlStore
}

@inject('navigationStore', 'wpmlStore')
@observer
export class HeaderHamburgerComponent extends Component<HamburgerProps> {
  render() {
    const { navigationStore, wpmlStore, data } = this.props
    const { lang } = wpmlStore
    const { themeSettingsLanguages } = data.wp.themeSettings.themeSettingsLanguages
    const { hamburgerTitle } =
      lang === 'en'
        ? themeSettingsLanguages.englishSettings.settingsHeader
        : themeSettingsLanguages.swedishSettings.settingsHeader
    const { menuOpen, toggleMenu } = navigationStore
    const hamburgerWrapperClasses = [s.hamburgerWrapper]
    const hamburgerClasses = [s.hamburger]
    const hamburgerTitleClasses = [s.hamburgerTitle]

    if (menuOpen) {
      hamburgerWrapperClasses.push(s.open)
      hamburgerClasses.push(s.open)
      hamburgerTitleClasses.push(s.open)
    }

    return (
      <div className={hamburgerWrapperClasses.join(' ')}>
        <div className={hamburgerClasses.join(' ')} onClick={toggleMenu}>
          <div className={s.bar}></div>
          <div className={s.bar}></div>
          <div className={s.bar}></div>
        </div>
        <div className={hamburgerTitleClasses.join(' ')}>{hamburgerTitle}</div>
      </div>
    )
  }
}

export const HeaderHamburger = (props: IHeaderHamburger) => (
  <StaticQuery
    query={query}
    render={data => <HeaderHamburgerComponent {...props} data={data} />}
  />
)

const query = graphql`
  query {
    wp {
      themeSettings {
        themeSettingsLanguages {
          themeSettingsLanguages {
            englishSettings {
              settingsHeader {
                hamburgerTitle
              }
            }
            swedishSettings {
              settingsHeader {
                hamburgerTitle
              }
            }
          }
        }
      }
    }
  }
`
