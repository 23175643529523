import React from 'react'
import { IAssetDownloadGridSection } from '~models'
import s from './AssetDownloadGrid.module.scss'
import { Container } from '~views/shared/components'
import BackgroundImage from '~views/shared/components/BackgroundImage'

interface Props {
  section: IAssetDownloadGridSection
}

export class AssetDownloadGridSection extends React.Component<Props> {
  renderImage = (item, key) => {
    const { section } = this.props
    const { imageHoverText } = section
    const { image } = item
    return (
      <div className={s.card} key={key}>
        <a href={image.localFile.url} target="_blank">
          <div className={s.downloadText}>{imageHoverText}</div>
          <BackgroundImage image={image} className={s.image} />
        </a>
      </div>
    )
  }

  render() {
    const { section } = this.props
    const { sectionTitle, sectionText, images } = section
    return (
      <section data-sectionname="AssetDownloadGrid">
        <Container noPadding className={s.container}>
          <h2 className={s.sectionTitle}>{sectionTitle}</h2>
          <div className={s.sectionText}>
            <p className="ingress">{sectionText}</p>
          </div>
          <div className={s.cardsContainer}>
            {images &&
              images.map((item, key) => {
                if (item) return this.renderImage(item, key)
              })}
          </div>
        </Container>
      </section>
    )
  }
}
