import { observable } from 'mobx'

export class ContextStore {
  @observable
  context: any

  @observable
  location: any

  @observable
  startpage: boolean = false
}
