import { observable, action } from 'mobx'
import { Store } from './Store'
import axios from 'axios'

export class ContactFormStore {
  rootStore: Store

  dateToday: Date = new Date()

  @observable
  checkInDate: Date = new Date()

  @observable
  checkOutDate: Date

  @observable
  minCheckOutDate: Date

  @observable
  maxCheckInDate: Date

  @observable
  numberOfGuests: string = ''

  @observable
  business: string = ''

  @observable
  contactPerson: string = ''

  @observable
  email: string = ''

  @observable
  phoneNr: string = ''

  @observable
  message: string = ''

  @observable
  activities: string[] = []

  @observable
  roomType: string

  @observable
  agreeTerms: boolean = false

  arrivalDate: string

  departureDate: string

  @observable
  initiateScroll: () => void = null

  @observable
  checkInOpen: boolean = false

  @observable
  checkOutOpen: boolean = false

  @observable
  errorText: string = ''

  @observable
  url: string

  @observable
  lang: string

  @action reset = () => {
    this.checkInDate = new Date()
    this.checkOutDate = new Date()
    this.numberOfGuests = ''
    this.business = ''
    this.contactPerson = ''
    this.email = ''
    this.phoneNr = ''
    this.message = ''
    this.activities = []
    this.roomType = ''
    this.agreeTerms = false
    this.checkInOpen = false
    this.checkOutOpen = false
    this.errorText = ''
  }

  @action
  toggleCheckIn = () => {
    if (this.checkInOpen) {
      setTimeout(() => (this.checkInOpen = false), 100)
    } else {
      this.checkInOpen = !this.checkInOpen
    }
  }

  @action
  toggleCheckOut = () => {
    if (this.checkOutOpen) {
      setTimeout(() => (this.checkOutOpen = false), 100)
    } else {
      this.checkOutOpen = !this.checkOutOpen
    }
  }

  @action
  setMinCheckOutDate = (date) => {
    this.minCheckOutDate = new Date(date)
    return this.minCheckOutDate.setDate(date.getDate() + 1)
  }

  @action
  setMaxCheckInDate = (date) => {
    if (date !== this.dateToday) {
      this.maxCheckInDate = new Date(date)
      return this.maxCheckInDate.setDate(date.getDate() - 1)
    }
  }

  formatDateToSlimFormat = (date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  @action
  onSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('arrival_date', this.arrivalDate)
    formData.append('departure_date', this.departureDate)
    formData.append('room_type', this.roomType)
    formData.append('message', this.message)
    formData.append('business', this.business)
    formData.append('contact', this.contactPerson)
    formData.append('email', this.email)
    formData.append('phone_number', this.phoneNr)
    formData.append('number_guests', this.numberOfGuests)
    formData.append('activities', JSON.stringify(this.activities))
    formData.append('terms', JSON.stringify(this.agreeTerms))
    formData.append('lang', this.lang)

    const isLocal =
      typeof window !== 'undefined' && window.location.hostname === 'localhost'

    const url = isLocal ? this.url : this.url.replace(/^http:\/\//i, 'https://')

    axios
      .post(url + '/wp-json/route/v1/contactForm', formData)
      .then((response) => {
        this.reset()
        this.errorText = response.data
        if (typeof window !== 'undefined') {
          ;(window as any).dataLayer.push({
            event: 'gtm.submit.success',
          })
        }
      })
      .catch((error) => {
        if (error.response) {
          this.errorText = error.response.data[0]
        } else {
          this.errorText =
            this.lang == 'sv'
              ? 'Något har gått fel, försök igen senare.'
              : 'Something went wrong, please try again later.'
        }
      })
  }
}
