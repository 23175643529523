import { observable, runInAction } from 'mobx'
import { Thumbs } from 'swiper'
import { enableBodyScrolling, disableBodyScrolling } from '~libs/utils'

export class NavigationStore {
  @observable
  headerHeight: number = 0

  @observable
  menuOpen: boolean = false

  @observable
  submenuOpen: boolean = false

  @observable
  activeSubmenuIndex: number = 0

  @observable
  bookingmenuOpen: boolean = false

  @observable
  activityMenu: string = 'rum'

  @observable
  openMenuItems: Array<number> = []

  @observable
  navigationMenuOpenPageYOffset: number = 0

  closeMenu = () => {
    this.menuOpen = false
    this.submenuOpen = false
    this.openMenuItems = []
    enableBodyScrolling()
    if (typeof window !== 'undefined' && window) {
      window.scrollTo(0, this.navigationMenuOpenPageYOffset)
    }
  }

  toggleBookingMenu = () => {
    if (!this.bookingmenuOpen) {
      this.bookingmenuOpen = true
    } else {
      this.bookingmenuOpen = false
    }
  }

  toggleActivityMenu = (activity) => {
    switch (activity) {
      case 'Golf':
        this.activityMenu = 'golf'
        break
      case 'Rum':
      case 'Room':
        this.activityMenu = 'rum'
        break
      case 'Bord':
      case 'Table':
        this.activityMenu = 'bord'
        break
    }
  }

  toggleSubmenu = (key) => {
    if (!(this.submenuOpen && this.activeSubmenuIndex !== key)) {
      this.submenuOpen = !this.submenuOpen
    }
    if (window.innerWidth >= 1200) {
      this.menuOpen = false
    }
    if (this.openMenuItems.find((item) => item === key)) {
      runInAction(() => {
        this.activeSubmenuIndex = key
        this.openMenuItems = this.openMenuItems.filter((item) => item !== key)
      })
    } else {
      this.openMenuItems = this.openMenuItems.filter(
        (item) => item === this.activeSubmenuIndex,
      )
      runInAction(() => {
        this.activeSubmenuIndex = key
        this.openMenuItems.push(key)
      })
    }
  }

  toggleMenu = () => {
    if (!this.menuOpen && this.submenuOpen) {
      this.menuOpen = true
      this.submenuOpen = false
      this.openMenuItems = []
    } else {
      this.menuOpen = !this.menuOpen
      const tempCurrentYPos = typeof window !== 'undefined' && window.pageYOffset
      if (this.menuOpen) {
        if (window.innerWidth < 1200) {
          disableBodyScrolling()
        }
        this.navigationMenuOpenPageYOffset = tempCurrentYPos
      } else {
        this.closeMenu()
      }
    }
  }
}
