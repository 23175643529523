import React from 'react'
import { IActivityCalendarSection, IAllWordpressActivityPage } from '~models'
import s from './ActivityCalendar.module.scss'
import { Container } from '~views/shared/components'
import { graphql, StaticQuery } from 'gatsby'
import { inject, observer } from 'mobx-react'
import { ActivityFilterStore, WpmlStore } from '~stores'
import ActivitiesContainer from './ActivitiesContainer'

export interface ActivityCalendarSectionProps {
  section: IActivityCalendarSection
  pages: IAllWordpressActivityPage
  activityFilterStore?: ActivityFilterStore
  wpmlStore?: WpmlStore
}

@inject('activityFilterStore', 'wpmlStore')
@observer
export class ActivityCalendarSectionComponent extends React.Component<ActivityCalendarSectionProps> {
  constructor(props: ActivityCalendarSectionProps) {
    super(props)
    const { pages, activityFilterStore } = this.props
    const { nodes } = pages
    const { sortActivitiesByYear, setUnsortedActivities } = activityFilterStore

    setUnsortedActivities(nodes)
    sortActivitiesByYear(nodes)
  }

  langFilter = (arr) => {
    const { wpmlStore } = this.props
    const { lang } = wpmlStore
    return arr.filter((e) => e.languageCode.slice(0, 2) === lang)
  }

  render() {
    const { section, activityFilterStore, wpmlStore } = this.props
    const { lang } = wpmlStore
    const { sectionTitle, sectionText } = section
    const { filteredAndSortedActivitiesByYear } = activityFilterStore
    const isEn = lang === 'en'
    const noActivities = isEn ? 'No activities found' : 'Inga aktiviteter hittades'

    const emptyResults =
      Object.entries(filteredAndSortedActivitiesByYear).length === 0 &&
      filteredAndSortedActivitiesByYear.constructor === Object

    return (
      <section data-sectionname="ActivityCalendar">
        <Container noPadding fluid className={s.customContainer}>
          <h1 className={s.title}>{sectionTitle}</h1>
          <div className={s.text}>
            <p className="ingress">{sectionText}</p>
          </div>
        </Container>
        {Object.keys(filteredAndSortedActivitiesByYear)
          .sort()
          .map((year) => (
            <ActivitiesContainer
              activities={this.langFilter(filteredAndSortedActivitiesByYear[year])}
              year={year}
              key={year}
              lang={lang}
            />
          ))}
        {emptyResults && (
          <div className={s.noFilterResults}>
            <h1>{noActivities}</h1>
          </div>
        )}
      </section>
    )
  }
}

export const ActivityCalendarSection = (props: any) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <ActivityCalendarSectionComponent {...props} pages={data.allWpPage} />
    )}
  />
)

const query = graphql`
  query {
    allWpPage(
      filter: {
        title: { ne: "SWACE_PLACEHOLDER" }
        page: { pageType: { eq: "activity_page" } }
      }
      sort: { page: { activityStartDate: ASC } }
    ) {
      nodes {
        id
        slug
        date
        languageCode
        link
        page {
          activityPreviewTitle
          activityPreviewText
          activityStartDate
          activityEndDate
          activityPreviewImage {
            localFile {
              url
              childImageSharp {
                image: gatsbyImageData(
                  width: 850
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
