import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { IHeaderCTA } from '~models'
import s from './Header.module.scss'
import { DynamicLink } from '~views/shared/components/DynamicLink'
import { inject, observer } from 'mobx-react'
import { NavigationStore, WpmlStore } from '~stores'
import { getDevice } from '~libs/utils'

interface Props {
  data: IHeaderCTA
  navigationStore?: NavigationStore
  wpmlStore?: WpmlStore
}

@inject('navigationStore', 'wpmlStore')
@observer
export class HeaderCTAComponent extends Component<Props> {
  render() {
    const { wpmlStore, navigationStore, data } = this.props
    const { menuOpen, toggleBookingMenu } = navigationStore
    const { lang } = wpmlStore
    const { themeSettingsLanguages } = data.wp.themeSettings.themeSettingsLanguages
    const { headerCta } =
      lang === 'en'
        ? themeSettingsLanguages.englishSettings.settingsHeader
        : themeSettingsLanguages.swedishSettings.settingsHeader
    const { headerCtaTitle } = headerCta

    const ctaClasses = [s.cta]

    if (menuOpen) {
      ctaClasses.push(s.open)
    }

    if (getDevice() === 'windows') {
      ctaClasses.push(s.windows)
    }

    return (
      <div>
        <div className={ctaClasses.join(' ')}>
          <div className={s.ctaTitle} onClick={() => toggleBookingMenu()}>
            <span>{headerCtaTitle}</span>
          </div>
        </div>
      </div>
    )
  }
}

export const HeaderCTA = (props: IHeaderCTA) => (
  <StaticQuery
    query={query}
    render={(data) => <HeaderCTAComponent {...props} data={data} />}
  />
)

const query = graphql`
  query {
    wp {
      themeSettings {
        themeSettingsLanguages {
          themeSettingsLanguages {
            englishSettings {
              settingsHeader {
                headerCta {
                  headerCtaLink
                  headerCtaLinkTwo
                  headerCtaTitle
                }
              }
            }
            swedishSettings {
              settingsHeader {
                headerCta {
                  headerCtaLink
                  headerCtaLinkTwo
                  headerCtaTitle
                }
              }
            }
          }
        }
      }
    }
  }
`
